import {onMounted, onUnmounted, ref, computed} from 'vue';
import {useBannerStore} from "@/src/store/banners";
import {storeToRefs} from "pinia";
import {theme} from "@/theme";

const usePromoBanner = (): any => {

  const banner = useBannerStore()
  const {activePromo, isBannerVisible} = storeToRefs(banner);

  const activePromoData = ref(theme.promo[activePromo.value]);

  const checkDate = () => activePromo.value && activePromoData.value.startDate && new Date() >= activePromoData.value.startDate &&
    activePromoData.value.endDate && new Date() <= activePromoData.value.endDate;

  const activePromoPeriod = ref(checkDate());
  const isPromoActive = computed(() => activePromo.value && activePromoData.value.active && activePromoPeriod.value)
  let blackFridayCheckInterval: number;

  if (!import.meta.env.SSR) {
    const checkDealStarted = () => activePromoPeriod.value = checkDate();

    onMounted(() => {
      checkDealStarted();
      blackFridayCheckInterval = setInterval(checkDealStarted, 1000)
    });

    onUnmounted(() => {
      clearInterval(blackFridayCheckInterval)
    })
  }
  return {isPromoActive, activePromoData};
};

export {
  usePromoBanner,
};
