<template>
  <div class="c-layover">
    <div class="c-popup w-full sm:max-w-[530px]" :class="classes">
      
      <div class="bg-image">
        <img v-if="props.bgImage" :src="props.bgImage" class="" alt="popup blurred background image"/>
      </div>
      
      <div class="c-popup-header" :class="{ __border: headerDivider, 'h-56': !title && (props.showClose || $slots.actions)}">
        <div class="c-popup-header-actions">
          <slot name="actions"/>
        </div>
        <div v-if="props.title" class="c-popup-header-title">
          {{ props.title }}
        </div>
        <div class="c-popup-header-close" v-if="props.showClose">
          <div class="p-16 cursor-pointer" @click="emit('close')">
            <se-icon name="x" :size="24"/>
          </div>
        </div>
      </div>
      <div class="c-popup-content-wrapper">
        <div ref="popupBody" class="c-popup-body">
          <slot/>
        </div>
        <div class="c-popup-footer" v-if="$slots.footer">
          <slot name="footer"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {onBeforeUnmount, onMounted, withDefaults} from 'vue';
import SeIcon from '../icon/Icon.vue';
import SeImg from "@/src/shared/ui-kit/img/Img.vue";

const props = withDefaults(defineProps<{
  showClose?: boolean,
  title?: string,
  classes?: string,
  centeredContent?: boolean,
  bgImage?: string,
  headerDivider?: boolean,
}>(), {
  showClose: true,
  title: '',
  classes: '',
  centeredContent: true,
  headerDivider: false,
});

const emit = defineEmits(['close']);

onMounted(() => {
  document.documentElement.style.cssText = 'overflow:hidden !important';
})

onBeforeUnmount(() => {
  document.documentElement.style.cssText = 'overflow:auto !important';
})
</script>

<script lang="ts">
export default {
  name: 'SePopup',
};
</script>

<style>

</style>
