<template>
  <div class="w-full">
    <template v-if="siteName !== 'Seeer'">
      <div v-if="paypalEnabled" class="mb-2">
        <p v-if="!stripeEnabled && !paynlEnabled" class="font-size-small">
          You can pay with your normal credit card via PayPal
        </p>
        <button
          type="button"
          class="btn btn-paypal d-block mx-0"
          :disabled="!amount"
          :class="{ 'is-loading': addFundsLoading }"
          data-cy="addfunds-button"
          @click="submitAddFunds('paypal', advisorId, amount, finalRedirectUrl)"
        />
        <div class="text-black text-b-xs mt-[4px] text-end">
          <p class="font-size-small text-center">
            The safer, easier way to pay
          </p>
          <img
            v-if="!stripeEnabled && !paynlEnabled"
            src="/static/images/paypal-cards.png"
            class="float-right"
            width="120"
            height="20"
            alt="cards"
          />
        </div>
      </div>

      <p class="line-on-side text-neutral-60 text-center text-b-xs mx-2 !my-24">
        <span>or</span>
      </p>
    </template>

    <div v-if="paynlEnabled" class="mb-2">
      <button
        type="button"
        class="btn d-flex items-center justify-center gap-2 mb-2 btn-paynl bg-primary-100"
        :disabled="!amount"
        :class="{ 'is-loading': addFundsLoading }"
        data-cy="addfunds-button"
        @click="submitAddFunds('paynl', advisorId, amount, finalRedirectUrl)"
      >
        <span v-if="siteName === 'Seeer'">Pay Now</span>
        <span v-else>Pay with Bank or Card</span>
      </button>
    </div>

    <div v-if="stripeEnabled" class="mb-2">
      <button
        type="button"
        class="btn d-flex items-center justify-center gap-2 mb-2 btn-paynl bg-primary-100"
        :disabled="!amount"
        :class="{ 'is-loading': addFundsLoading }"
        data-cy="addfunds-button"
        @click="submitAddFunds('stripe', advisorId, amount, finalRedirectUrl)"
      >
        <span>Pay with Card or Bank</span>
      </button>
    </div>

    <div class="payment-text text-b-s text-neutral-80 mb-4 xs:mr-[15%]">
      If you're satisfied, continue by making a payment. If not, you have the option to change advisors using the funds you have left.
    </div>

  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { usersStore } from '@/src/store/users';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import config from '@/config';
import { theme } from "@/theme";

const { siteName } = theme.general;

const props = withDefaults(defineProps<{
    redirectUrl: string,
    advisorId: number,
    amount: number
}>(), {
  redirectUrl: 'home',
  advisorId: 0,
});

const stripeEnabled = config.stripe.enabled;
const paypalEnabled = config.paypal.enabled;
const paynlEnabled = config.paynl.enabled;

const { $route } = getGlobalProperties();

const user = usersStore();
const { submitAddFunds } = user;
const { addFundsLoading, savedRedirectUrl, currentUser } = storeToRefs(user);

const finalRedirectUrl = computed(() => savedRedirectUrl.value || props.redirectUrl);
</script>

<script lang="ts">
export default {
  name: 'SePaymentMethod',
};
</script>
