<template>
  <div class="c-info-box" :class="[`c-info-box--${props.type}`]"
  >
    <se-icon name="info" :size="20" class="shrink-0"/>
    <div>
      <p v-if="props.title" class="text-h-m font-bold mb-1 leading-[1.2]">{{props.title}}</p>
      <p class="relative text-b-s">{{props.text}}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import {InfoBoxType} from "@/src/shared/ui-kit/info-box/types/InfoBoxType";
import SeIcon from "@/src/shared/ui-kit/icon/Icon.vue";

const props = withDefaults(defineProps<{
  type?: InfoBoxType,
  title?: string,
  text: string,
}>(), {
  type: 'default',
  title: '',
});

const icons = {
  alert: ''
}
</script>

<script lang="ts">
export default {
  name: 'SeInfoBox',
};
</script>
