import {PaymentConfig} from "../../_core/types/theme/Payment.type";

const payment: PaymentConfig = {
    success: {
        seo: {
            title: "Payment confirmation - MysticWork",
            description: ""
        }
    },
    fail: {
        seo: {
            title: "Payment failed - MysticWork",
            description: ""
        }
    },
    thankYou: {
        seo: {
            title: "Thank you - MysticWork",
            description: ""
        }
    },

};

// @ts-ignore
export default payment;
