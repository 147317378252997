<template>
  <div
    class="c-avatar"
    :style="{ '--se-avatar-size': `${props.size / 16}rem` }"
    :class="[
      getType && `c-avatar--${getType}`,
    ]"
    :data-initials="initials"
  >
    <suspense>
      <se-img v-if="props.src" :alt="props.name" :src="imageSrc(props.src)" />
    </suspense>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import SeImg from '../img/Img';
import useImageSrc from "@/src/shared/helpers/useImageSrc";

const props = withDefaults(defineProps<{
  id?: number,
  size?: number,
  name?: string,
  src?: string,
}>(), {
  id: 0,
  size: 48,
  name: '',
  src: '',
});

const initials = computed(() => props.name.match(/\b\w/g)?.join('').substring(0, 2) || '');

const types: string[] = ['primary', 'feature', 'error', 'warning'];

const getType = computed(() => types[props.id % types.length]);

const {imageSrc} = useImageSrc()

</script>

<script lang="ts">
export default {
  name: 'SeAvatar',
};
</script>
