<template>
  <se-dropdown placement="bottom-end">
    <template #trigger>
      <slot />
    </template>
    <se-dropdown-item>
      <Link href="/dashboard/profile/edit" class="flex items-center text-black">
        <se-icon name="pen-line" :size="20" class="mr-8" />Edit profile
      </Link>
    </se-dropdown-item>
    <se-dropdown-item>
      <Link href="/dashboard/profile/settings" class="flex items-center text-black">
        <se-icon name="settings" :size="20" class="mr-8" />Settings
      </Link>
    </se-dropdown-item>
    <se-dropdown-item v-if="subscriptionEnabled && isAdvisor">
      <Link href="/dashboard/profile/plans" class="flex items-center text-black">
        <se-icon name="user-check" :size="20" class="mr-8" />Subscription Plans
      </Link>
    </se-dropdown-item>
    <se-dropdown-item v-if="subscriptionEnabled">
      <Link href="/dashboard/unsubscribe" class="flex items-center text-black">
        <se-icon name="mail" :size="20" class="mr-8" />Unsubscribe
      </Link>
    </se-dropdown-item>
    <se-dropdown-item @click="$auth.logout()">
      <div class="flex items-center text-black">
        <se-icon name="log-out" :size="20" class="mr-8" />Logout
      </div>
    </se-dropdown-item>
  </se-dropdown>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import SeDropdown from '@/src/shared/ui-kit/dropdown/Dropdown';
import SeDropdownItem from '@/src/shared/ui-kit/dropdown/DropdownItem';
import { getGlobalProperties } from '@/src/shared/helpers/getGlobalProperties';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import { usersStore } from '@/src/store/users';
import Link from '@/src/components/Link';
import {theme} from "@/theme";

const {subscriptionEnabled} = theme.general;

const { $auth } = getGlobalProperties();
const { isAdvisor } = storeToRefs(usersStore());

</script>

<script lang="ts">
export default {
  name: 'SeHeaderUserMenu',
};
</script>
