import {PaymentConfig} from "../../_core/types/theme/Payment.type";

const payment: PaymentConfig = {
    success: {
        seo: {
            title: "Payment confirmation - PsychicVOP",
            description: ""
        }
    },
    fail: {
        seo: {
            title: "Payment failed - PsychicVOP",
            description: ""
        }
    },
    thankYou: {
        seo: {
            title: "Thank you - PsychicVOP",
            description: ""
        }
    },

};

// @ts-ignore
export default payment;
