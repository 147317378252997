<template>
  <se-popup v-if="isVisible" title="Leave Feedback" classes="sm:w-[448px]" @close="closeModal">
    <div class="flex flex-col items-start justify-center gap-16">
      <div v-if="advisorInfo" class="flex flex-column items-center justify-center gap-16 w-full my-24 ">
        <se-avatar-chat-option
            :id="advisorInfo.id"
            :size="68"
            :name="advisorInfo.name"
            :src="advisorInfo.avatar"
            :chat-action="chatMode"
        />
        <p class="text-h-m pt-16 text-center font-bold">{{ advisorInfo.name }}</p>
      </div>
      <p class="text-h-m font-semibold">How would you rate the session overall?</p>
      <se-rating-stars class="align-self-start" :can-change="true" v-model="feedbackForm.rating"/>
      <div class="feedback-message w-full">
        <p class="text-b-xs text-neutral-60 mb-8 font-medium">Add a comment (Optional)</p>
        <se-textarea v-model="feedbackForm.message" class="min-h-[72px]" placeholder="Type a feedback comment here..." />
      </div>
    </div>
    <template #footer>
      <se-button class="w-full" @click="saveFeedBack(redirectUrl)" :loading="savingFeedbackLoader">
        Leave Public Feedback
      </se-button>
    </template>
  </se-popup>
</template>

<script setup lang="ts">
import {storeToRefs} from "pinia";
import {computed} from "vue";
import {useFeedbacksStore} from "@/src/store/feedbacks";
import {getGlobalProperties} from "@/src/shared/helpers/getGlobalProperties";
import useFeedbacks from "@/src/composables/useFeedbacks";
import SePopup from "@/src/shared/ui-kit/popup/Popup.vue";
import SeAvatarChatOption from "@/src/shared/ui-kit/avatar-chat-option/AvatarChatOption.vue";
import SeRatingStars from "@/src/modules/feedback/components/rating-stars.vue";
import SeTextarea from "@/src/shared/ui-kit/textarea/Textarea.vue";
import SeButton from "@/src/shared/ui-kit/button/Button.vue";

const props = withDefaults(defineProps<{
  canChange?: boolean,
}>(), {
  canChange: false,
});

const {$router, $route} = getGlobalProperties()

const feedbacks = useFeedbacksStore()
const {feedbackForm, savingFeedbackLoader, advisorInfo, chatMode} = storeToRefs(feedbacks)
const {resetFeedbackForm} = feedbacks

const {saveFeedBack} = useFeedbacks()

const users = usersStore()
const {isUser}= storeToRefs(users)


const redirectUrl = computed(() => {
  if (feedbackForm.value.chatId) {
    return { path: '/dashboard/history/:id', params: { id: feedbackForm.value.chatId } }
  } else {
    return '/home'
  }
})

const closeModal = () => {
  resetFeedbackForm()
  if ($route.path !== redirectUrl.value) {
    $router.push(redirectUrl.value)
  }
}

const isVisible = computed(() => feedbackForm && feedbackForm.value.chatId !== null && isUser.value)

</script>

<script lang="ts">
import { usersStore } from '@/src/store/users'
export default {
  name: 'SeAddEditFeedbackPopup',
}

</script>

<style scoped lang="scss">

</style>