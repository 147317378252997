import Form from '@/src/helpers/form-validation'
import { defineStore } from 'pinia'
import { inboxStore } from '@/src/store/inbox'
import { chatStore } from '@/src/store/chat'
import _ from '@/src/helpers/arrayHelpers'
import { isProxy, toRaw } from 'vue'
import config from "@/config.js";
import {Howl} from "howler";

export const usersStore = defineStore('users', {
	namespaced: true,
	state: () => ({
		prefetchedData: {}, // Data read on client before application is mounted, if update it right after that a lot of hydration mismatch issues caused.
		appSettings: null,
		currentUser: {},
		currentUserBlocks: {}, // Stores blocks made by current user if the data received before user data.
		currentUserInbox: [], // Stores inbox data for current user if the data received before user data.
		currentUserInboxShort: [], // Stores inbox data for current user if the data received before user data.
		isAuthenticated: false,
		isAdmin: false,
		isSupport: false,
		isSales: false,
		isUser: false,
		isAdvisor: false,
		isActive: false,
		isInactive: false,
		unreadNotifications: 0,
		loading: false,
		userProfile: null,
		userProfileLoading: false,
		userFeedBacks: [],
		userFeedBacksPage: 1,
		userFeedBacksTotal: 0,
		userBestFeedBacks: [],
		userBestFeedBacksPage: 1,
		userBestFeedBacksTotal: 0,
		profileNotFound: false,
		addFundsLoading: false,
		landingProfileNotFound: false,
		registerForm: new Form({
			name: '',
			email: '',
			url: '',
			password: '',
			password_confirmation: ''
		}),
		usersInApp: [],
		onlineUsers: [],
		//change online status loading
		changeStatusLoading: false,
		//my advisors page
		myAdvisors: [],
		gettingAdvisorsList: false,
		//blocks
		blockLoading: false,
		presence: null,
		categories: [],
		advisorsIndexList: [],
		myChattedAdvisors: [],
		myChattedAdvisorsCurrentPage: 0,
		myChattedTotalAdvisors: 0,
		notMyChattedAdvisors: [],
		notMyChattedAdvisorsCurrentPage: 0,
		notMyChattedTotalAdvisors: 0,
		topAdvisors: [],
		topAdvisorsCurrentPage: 0,
		topTotalAdvisors: 0,
		onlineAdvisors: [],
		onlineAdvisorsCurrentPage: 0,
		onlineTotalAdvisors: 0,
		newAdvisors: [],
		newAdvisorsCurrentPage: 0,
		newTotalAdvisors: 0,
		// notify me
		notifyMeAbout: null,
		cancelNotify: null,
		//landing profile page saved user
		landingPageProfile: {},
		//is typing now
		typing_now: [],
		//promotions
		promotions: [],
		//advisor category by name
		category: null,
		categoryNotFound: false,
		categoryAdvisors: [],
		categoryCurrentPage: 0,
		categoryTotalAdvisors: 0,
		debug: null,
		//loading inbox
		inboxLoading: false,
		closedInboxLoading: false,
		//free minutes
		loadingFreeMinutes: false,
		//inbox pagination
		// unreadInbox: 0,
		inboxCurrentPage: 1,
		inboxClosedPage: 0,
		inboxTotal: 0,
		inboxClosedTotal: 0,
		inboxShortCurrentPage: 1,
		inboxShortClosedPage: 0,
		inboxShortTotal: 0,
		inboxShortClosedTotal: 0,
		// advisorProfilePopUp: null,
		userInfo: [],
		showVerifyUser: false,
		lastVerifyUserResult: false,
		showStartIdentityVerification: false,
		showFeedbacksModal: false,
		feedbacksAdvisorId: 0,
		showFeedbacks: 5,
		minutesChosenOnRegister: 15,
		subscribingToPlan: null,
		subscribeLoading: false,
		isActiveSubscriptionApproval: false,
		plans: [],
		plansLoading: true, // True by default, to always show loading state instantly on page load
		savedAction: null,
		savedParam: null,
		savedRedirectUrl: null,
		chargeAmount: null,
		totalClients: 0,
	}),
	
	getters: {
		messages() {
			if (!this.chatInfo || !this.chatInfo.id)
				return [];
			return this.chatInfo.messages;
		},
		sounds() {
			const themeName = config?.theme?.name || 'seeer'  // Default to seeer if theme is not available
			return {
				message: new Howl({
					src: [`/static/sounds/${themeName}/new_message.mp3`],
					loop: false,
					onend: function () {
					}
				}),
				chat: new Howl({
					src: [`/static/sounds/${themeName}/new_chat.mp3`],
					loop: true,
					onend: function () {
					}
				}),
				mail: new Howl({
					src: [`/static/sounds/${themeName}/new_notification.mp3`],
					loop: false,
					onend: function () {
					}
				}),
			}
		},
	},

	hydrate(state, initialState) {
	},

	actions: {

		updateCurrentUserFromPrefetched() {
			for (let fieldName in this.prefetchedData) {
				this[fieldName] = this.prefetchedData[fieldName]
			}
		},

		// showAdvisorProfilePopUp(id) {
		// 	this.advisorProfilePopUp = id;
		// },
		// hideAdvisorProfilePopUp() {
		// 	this.advisorProfilePopUp = null;
		// },
		// updateUnreadInbox(full = false) {
		// 	if (!full) {
		// 		if (this.currentUser.inboxShort) {
		// 			this.unreadInbox = this.currentUser.inboxShort.reduce((sum, curr) => {
		// 				return sum + (curr.unread_count ?? 0)
		// 			}, 0)
		// 		} else {
		// 			this.unreadInbox = 0
		// 		}
		// 	} else {
		// 		if (this.currentUser.inbox) {
		// 			this.unreadInbox = this.currentUser.inbox.reduce((sum, curr) => {
		// 				return sum + (curr.unread_count ?? 0)
		// 			}, 0)
		// 		} else {
		// 			this.unreadInbox = 0
		// 		}
		// 	}
		// },
		setClosedInboxMessages(messages, full = false) {
			if (!full) {
				const ids = this.currentUser.inboxShort.map(item => item.id);
				// let user = JSON.parse(JSON.stringify(this.currentUser));
				this.currentUser.inboxShort.push(...messages.filter(item => ids.indexOf(item.id) === -1));
				// user.inbox.push(...messages.filter(item => ids.indexOf(item.id) === -1));
				// this.currentUser = user;
			} else {
				const ids = this.currentUser.inbox.map(item => item.id);
				this.currentUser.inbox.push(...messages.filter(item => ids.indexOf(item.id) === -1));
			}
			// this.updateUnreadInbox(full)
		},
		setInboxClosedPage(value, full = false) {
			if (!full) {
				this.inboxShortClosedPage = value;
			} else {
				this.inboxClosedPage = value;
			}
		},
		setInboxClosedTotal(value, full = false) {
			if (!full) {
				this.inboxShortClosedTotal = value;
			} else {
				this.inboxClosedTotal = value;
			}
		},
		setInboxPage(value, full = false) {
			if (!full) {
				this.inboxShortCurrentPage = value;
			} else {
				this.inboxCurrentPage = value;
			}
		},
		setInboxTotal(value, full = false) {
			if (!full) {
				this.inboxShortTotal = value;
			} else {
				this.inboxTotal = value;
			}
		},
		setAddFundsLoading(value) {
			this.addFundsLoading = value;
		},
		setLoadingFreeMinutes(value) {
			this.loadingFreeMinutes = value;
		},
		setInboxLoading(value) {
			this.inboxLoading = value;
		},
		setClosedInboxLoading(value) {
			this.closedInboxLoading = value;
		},
		setDebug(value) {
			this.debug = value;
		},
		setCategories(value) {
			this.categories = value
			// Clearnin due to hydration error when new line with carrige return in this list.
			this.categories = []
			if (value) {
				for (let i in value) {
					let item = value[i]
					item.title = item.title.replace(/[\r\n]/, '')
					item.name = item.name.replace(/[\r\n]/, '')
					this.categories[this.categories.length] = item
				}
			}
		},
		setCategory(value) {
			this.category = value;
		},
		setCategoryNotFound(value) {
			this.categoryNotFound = value;
		},
		setCategoryAdvisors(value) {
			this.categoryAdvisors = value;
		},
		setCategoryCurrentPage(value) {
			this.categoryCurrentPage = value;
		},
		setCategoryTotalAdvisors(value) {
			this.categoryTotalAdvisors = value;
		},
		// setCurrentUser(user) {
		// 	this.currentUser = user;
		// },
		setCurrentUserBalance(value) {
			this.currentUser.amount = value;
		},
		setUserLoading(value) {
			this.loading = value;
		},
		setLandingPageProfile(profile) {
			this.landingPageProfile = profile;
		},
		setLandingProfileLoading(value) {
			this.userProfileLoading = value;
		},
		setLandingProfileNotFound(value) {
			this.landingProfileNotFound = value;
		},
		setProfileNotFound(value) {
			this.profileNotFound = value;
		},
		setUserProfile(value) {
			this.userProfile = value;
		},
		setCancelNotifyConfirmation(payload) {
			this.cancelNotify = payload;
		},
		setNotifyMeAbout(payload) {
			this.notifyMeAbout = payload;
		},
		setShowVerifyUser(value) {
			this.showVerifyUser = value;
		},
		setLastVerifyUserResult(value) {
			this.lastVerifyUserResult = value;
		},
		setShowStartIdentityVerification(value) {
			this.showStartIdentityVerification = value;
		},
		setShowFeedbacksModal(value) {
			this.showFeedbacksModal = value;
		},
		setShowFeedbacks(value) {
			this.showFeedbacks = value;
		},
		startShowFeedbacksModal(advisorId) {
			this.setShowFeedbacksModal(true)
			this.feedbacksAdvisorId = advisorId
		},
		advisorOnlineStatusChanged(payload) {
			if (!this.myAdvisors.data)
				return false;
			this.myAdvisors.data.forEach(adv => {
				if (adv.id === payload.user)
					adv.status = payload.status;
			})
		},
		setMinutesChosenOnRegister(value) {
			this.minutesChosenOnRegister = value;
		},
		setPresence(payload) {
			this.presence = payload;
		},
		setOnlineUsers(array) {
			// this.usersInApp = _.map(array, item => parseInt(item.uuid));
			// this.usersInApp = _.map(array, (item, key) => key);
			this.usersInApp = _.map(array, (item, key) => item.id);
		},
		setWhoOnline(value) {
			this.onlineUsers = value;
		},
		userJoined(user) {
			if (this.usersInApp.indexOf(user.id) === -1)
				this.usersInApp.push(user.id);
		},
		userLeft(user) {
			if (this.usersInApp.indexOf(user.id) !== -1)
				this.usersInApp.splice(this.usersInApp.indexOf(user.id), 1);
		},
		loadInboxMessages(object) {
			this.currentUser.inbox.forEach(inbox => {
				if (inbox.id === object.id)
					inbox.messages = object.messages;
			});
		},
		setChangeStatusLoading(value) {
			this.changeStatusLoading = value;
		},
		setCurrentUserOnlineStatus(value) {
			this.currentUser.status = value;
		},
		setCurrentUserAmount(e) {
			if (this.currentUser.id === e.id)
				this.currentUser.amount = e.amount;
		},
		setAccountActivated() {
			this.currentUser.account_status = 'active';
		},
		setNewCurrentUserBlocks(e) {
			this.currentUser.blocks.push({
				blocker_id: e.blocker.id,
				blocked_user_id: e.blocked.id
			})
		},
		setDeleteCurrentUserBlock(e) {
			this.currentUser.blocks.forEach(block => {
				if (block.blocker_id === e.blocker.id && block.blocked_user_id === e.blocked.id)
					this.currentUser.blocks.splice(this.currentUser.blocks.indexOf(block), 1)
			});
		},
		setBlockUserLoading(value) {
			this.blockLoading = value
		},
		setCurrentUserBlocks(value) {
			this.currentUser.blocks = value
			this.currentUserBlocks = value
		},
		setMyAdvisors(value) {
			this.myAdvisors = value
		},
		setLoadingMyAdvisors(value) {
			this.gettingAdvisorsList = value
		},
		setActiveChat(chat) {
			this.currentUser.active_chat = chat
		},
		setActiveChatStatus(status) {
			if (!this.currentUser.active_chat)
				return false;
			this.currentUser.active_chat.status = status
		},
		setActiveChatPaidMinutes(value) {
			if (!this.currentUser.active_chat) {
				return false
			}
			this.currentUser.active_chat.paid_minutes = value
		},
		setActiveChatIsFree(chat) {
			if (this.currentUser?.active_chat) {
				this.currentUser.active_chat.is_free = chat.is_free
				this.currentUser.active_chat.started_at = chat.started_at
			}
		},
		setActiveChatTimer(time) {
			if (this.currentUser?.active_chat) {
				this.currentUser.active_chat.started_at = time;
			}
		},
		setActiveChatPauses(pauses) {
			this.currentUser.active_chat.pauses = pauses;
		},
		setNewInboxMessage(message) {
			if (!this.currentUser?.inbox && !this.currentUser?.inboxShort) {
				return false;
			}

			this.currentUser.inbox.forEach(inbox => {
				if (inbox?.id && inbox.id == message.inbox_id) {
					inbox.is_closed_user_1 = 0;
					inbox.is_closed_user_2 = 0;
					inbox.last_message = message;
					if (message.sender_id !== this.currentUser.id) {
						inbox.unread_count++;
					}
					if (
						typeof inbox.messages == 'object'
					) {
						inbox.messages.push(message);
					}
					inbox.total_messages++;
				}
			})

			let inboxFound = false
			this.currentUser.inboxShort.forEach(inbox => {
				if (inbox?.id && inbox.id == message.inbox_id) {
					inboxFound = true
					inbox.last_message = message;
					if (message.sender_id !== this.currentUser.id) {
						inbox.unread_count++;
					}
					inbox.total_messages++;
				}
			})
			if (!inboxFound) {
				// Updating inboxes list if the new message to new advisor was sent.
				this.getInbox({ full: false, open: true, closed: true })
			}
		},
		setInboxCharge(payload) {
			this.currentUser.inbox.forEach(inbox => {
				if (inbox.id === payload.charge.inbox_id)
					inbox.active_charge = payload.charge;
			})
		},
		setUserInboxChanges(e) {
			this.currentUser.inbox.forEach(item => {
				if (item.id === e.inbox.id) {
					const user_id = item.user_1_id === this.currentUser.id ? 'is_closed_user_1' : 'is_closed_user_2';
					if (item[user_id] !== e.inbox[user_id]) {
						if (item[user_id]) {
							this.inboxClosedTotal = parseInt(this.inboxClosedTotal) - 1;
							this.inboxTotal = parseInt(this.inboxTotal) + 1;
						} else {
							this.inboxClosedTotal = parseInt(this.inboxClosedTotal) + 1;
							this.inboxTotal = parseInt(this.inboxTotal) - 1;
						}
					}
					item.is_closed_user_1 = e.inbox.is_closed_user_1;
					item.is_closed_user_2 = e.inbox.is_closed_user_2;
				}
			})
		},
		setChargeStatusChanged(e) {
			this.currentUser.inbox.forEach(inbox => {
				if (inbox.id === e.inbox_id) {
					if (e.charge.status === 'accepted')
						inbox.opponent_info.amount -= e.charge.amount;
					inbox.active_charge = null;
					if (inbox.messages)
						inbox.messages.forEach(msg => {
							if (msg.type === 'charge' && msg.charge.id === e.charge.id) {
								msg.charge.status = e.charge.status;
								msg = JSON.parse(JSON.stringify(msg))
							}
						});
				}
			})
		},
		setReadInbox(e) {
			this.currentUser.inbox.forEach(inbox => {
				if (inbox.id === parseInt(e.inbox_id)) {
					inbox.unread_count = 0;
					if (inbox.last_message && inbox.last_message.sender_id !== parseInt(e.user_id))
						inbox.last_message.is_unread = false;
					if (inbox.messages)
						inbox.messages.forEach(msg => {
							if (msg.sender_id !== parseInt(e.user_id)) {
								msg.is_unread = false;
							}
						});
				}
			});
			this.currentUser.inboxShort.forEach(inbox => {
				if (inbox.id === parseInt(e.inbox_id)) {
					inbox.unread_count = 0;
					if (inbox.last_message && inbox.last_message.sender_id !== parseInt(e.user_id))
						inbox.last_message.is_unread = false;
					if (inbox.messages)
						inbox.messages.forEach(msg => {
							if (msg.sender_id !== parseInt(e.user_id)) {
								msg.is_unread = false;
							}
						});
				}
			});
		},
		setInboxMessagesDeleted(e) {
			this.currentUser.inbox.forEach(inbox => {
				if (inbox.id === e.inbox.id) {
					inbox.unread_count = 0;
					inbox.last_message = null;
					inbox.messages = [];
				}
			});
		},
		setInviteUsed(e) {
			this.currentUser.inbox.forEach(inbox => {
				if (inbox.messages)
					inbox.messages.forEach(message => {
						if (message.type === 'invite' && parseInt(message.invite.id) === parseInt(e.invite_id))
							message.invite.is_used = 1;
					})
			})
		},
		setPromotions(value) {
			this.promotions = value;
		},
		setMyProfileChanged(e) {
			for (let field in this.currentUser) {
				for (let change in e.changes) {
					if (field === change)
						this.currentUser[field] = e.changes[change];
				}
			}
		},
		onlineStatusChanged(payload) {
			if (payload.status === 'offline') {
				if (this.onlineUsers[payload.id]) {
					delete this.onlineUsers[payload.id]
				}
			} else {
				this.onlineUsers[payload.id] = payload.status
			}
		},
		setIsTyping(e) {
			if (e.is_typing) {
				let filtered = _.filter(this.typing_now, item => item.user === e.user && item.is_typing === e.is_typing)
				if (filtered.length === 0) {
					this.typing_now.push(e);
				}
			} else {
				this.typing_now.forEach(item => {
					if (item.user === e.user && e.type === item.type) {
						this.typing_now.splice(this.typing_now.indexOf(item), 1)
					}
				})
			}
		},
		setFreeMinutesUsed(e) {
			this.currentUser.inbox.forEach(item => {
				if (item.user_1_id === e.advisor_id && item.user_2_id === e.client_id || item.user_2_id === e.advisor_id && item.user_1_id === e.client_id) {
					if (item.messages)
						item.messages.forEach(msg => {
							if (msg.type === 'minutes')
								msg.status = 'used';
						})
				}

			});
		},
		setFreeMinutesReceived(e) {
			this.currentUser.sent_free_minutes.push(e.free_minutes_record);
		},
		setSentFreeMinutes(value) {
			if (this.isUser)
				this.currentUser.sent_free_minutes = value;
			else
				this.currentUser.advisor_free_minutes = value;
		},
		setAppSettings(appSettings) {
			this.appSettings = appSettings;
		},
		setUserEmailVerified(verifiedStatus) {
			this.currentUser.email_verified = verifiedStatus;
		},

		getInboxById(id) {
			return this.$api.get(`/api/user/inbox/${id}`)
				.then(res => {
					const ids = toRaw(this.currentUser.inbox).reduce((total, value) => total.concat([value.id]), [])
					this.currentUser.inbox.push(...[res.data].filter(item => ids.indexOf(item.id) === -1))
					// this.updateUnreadInbox(true)
				})
		},
		async getPromotions(advisorId, chatMode) {
			let params = {}
			if (advisorId) {
				params['advisor_id'] = advisorId
				params['chat_mode'] = chatMode
			}
			return await this.$api.get('/api/settings/promotions', params)
				.then(res => {
					this.setPromotions(res.data)
				})
				.catch(async error => {
				})
		},
		async getGuestPromotions(advisorId, chatMode) {
			let params = {}
			if (advisorId) {
				params['advisor_id'] = advisorId
				params['chat_mode'] = chatMode
			}
			return await this.$api.get('/promotions', params)
				.then(res => {
					this.setPromotions(res.data)
				})
				.catch(async error => {
				})
		},
		//load settings
		async getSettings() {
			await this.$api.get('/api/settings')
				.then(res => {
					let settings = new Object();
					res.data.forEach(item => settings[item.title] = item.value);
					this.setAppSettings(settings);
				})
				.catch(error => {
				})
		},
		//change current user online status
		changeOnlineStatus(status) {
			this.setChangeStatusLoading(true)
			this.$api.put('/api/user/status', { status: status })
				.then(res => {
					if (res) {
						// If status is not changed, res is empty.
						this.setCurrentUserOnlineStatus(status)
					}
					this.setChangeStatusLoading(false)
				})
				.catch(error => {
					if (error.status == 403) {
						this.$toast({
							message: '<b>Error: </b>' + error.response,
							type: 'error'
						})
					} else if (error.response?.data) {
						this.$toast({
							message: '<b>Error: </b>' + error.response.data,
							type: 'error'
						})
					} else {
						console.trace(error)
					}
					this.setChangeStatusLoading(false)
				})
		},
		getSentFreeMinutes() {
			this.setLoadingFreeMinutes(true)
			return this.$api.get(`/api/user/free-minutes`)
				.then(res => {
					this.setLoadingFreeMinutes(false)
					this.setSentFreeMinutes(res.data);
					return true;
				})
				.catch(err => {
					console.error('getting free minutes', err, err.response)
					this.setLoadingFreeMinutes(false)
					return false;
				})
		},
		getActiveChat() {
			return this.$api.get('/api/chat/active')
				.then(res => {
					console.info(res, 'active')
					this.setActiveChat(res.data)
				})
				.catch(err => alert(JSON.stringify(err)))
		},
		// get current user info and put it into statement
		async getCurrentUser(tokenInfo = {}, preloadMode = false) {
			this.setUserLoading(true);
			// return await Promise.all([
			await Promise.all([
				this.$api.get('/api/user')
					.then(response => {
						this.currentUser = response.data

						if (!import.meta.env.SSR && window.Bugsnag) {
							window.Bugsnag.user = {
								id: response.data.data.id,
								name: response.data.data.name,
								email: response.data.data.email,
								role: response.data.data.role_name
							};
						}

						// this.getInbox();
						// this.getBlocks();

						// Placing preloaded data on its place if data was received before the current user.
						if (this.currentUserBlocks) {
							this.currentUser.blocks = this.currentUserBlocks
						}
						if (this.currentUserInbox) {
							this.currentUser.inbox = this.currentUserInbox
						}
						if (this.currentUserInboxShort) {
							this.currentUser.inboxShort = this.currentUserInboxShort
						}

						if (tokenInfo && tokenInfo.redirect && typeof tokenInfo.redirect === 'boolean')
							this.$router.push('/home');
						else if (tokenInfo && tokenInfo.redirect && typeof tokenInfo.redirect === 'string')
							this.$router.push(tokenInfo.redirect);

						return response
					})
					.catch(async error => {
						console.error(error, error.response)
						// this.setUserLoading(false);
						if (error.status === 403) {
							this.$toast({
								message: 'Your account has been blocked!',
								type: 'error'
							});
							let _this = this
							setTimeout(async function () {
								await _this.$auth.logout()
								_this.$router.push({ path: '/login' })
							}, 3000)
							return false
						}
					}),
				this.getInbox({ full: false, open: true, closed: true }),
				this.getBlocks(),
			])

			// Making user change happening when page is reloaded.
			const user = this.currentUser
			this.currentUser = {}
			// Saving preloading data.
			this.setCurrentUser(user, preloadMode)
			this.setUserLoading(false)
		},
		setCurrentUser(user, preloadMode = false) {
			this.prefetchedData = {}
			if (user?.id) {
				this.prefetchedData.currentUser = user
				this.prefetchedData.isAuthenticated = this.$auth.check()
				this.prefetchedData.isAdmin = user.role_name === 'admin'
				this.prefetchedData.isSupport = user.role_name === 'support'
				this.prefetchedData.isSales = user.role_name === 'sales'
				this.prefetchedData.isUser = user.role_name === 'user'
				this.prefetchedData.isAdvisor = user.role_name === 'advisor'
				this.prefetchedData.isActive = user.account_status === 'active'
				this.prefetchedData.isInactive = user.account_status === 'inactive'
				this.prefetchedData.unreadNotifications = user.total_unread_notifications
				// this.prefetchedData.unreadInbox is updated when inbox data loaded.
				if (!preloadMode) {
					this.updateCurrentUserFromPrefetched()
				}
			} else {
				this.currentUser = {}
				this.isAuthenticated = false
				this.isAdmin = false
				this.isSupport = false
				this.isSales = false
				this.isUser = false
				this.isAdvisor = false
				this.isActive = false
				this.isInactive = false
				this.unreadNotifications = 0
				// this.unreadInbox = 0
			}
		},
		//get chat free minutes
		getFreeMinutes(advisor) {
			return advisor?.free_minutes_left ?? 0
		},
		async getInbox(payload = {}) {
			const full = (payload ? (payload.full ?? true) : true)
			// const closed = (payload && payload.closed)
			const open = (payload ? (payload.open ?? true) : true)
			const unread = (payload ? (payload.unread ?? false) : false)
			const skip = (payload ? (payload.skip ?? '') : '')
			const page = (payload && payload.page ? payload.page : 1)
			const search = (payload && payload.search ? payload.search : "")

			// if (open) {
			// 	this.setInboxLoading(true)
			// }
			// if (closed) {
			// 	this.setClosedInboxLoading(true)
			// }

			let url = '/api/user/inbox'
			if (skip) {
				url += (url.match(/\?/) ? '&' : '?') + 'skip=' + skip
			} else {
				url += (url.match(/\?/) ? '&' : '?') + 'page=' + page
			}
			if (search) {
				url += (url.match(/\?/) ? '&' : '?') + 'search=' + encodeURIComponent(search)
			}
			url += (url.match(/\?/) ? '&' : '?') + 'open=' + (open ? '1' : '0')
			url += (url.match(/\?/) ? '&' : '?') + 'full=' + (full ? '1' : '0')
			// url += (url.match(/\?/) ? '&' : '?') + 'closed=' + (closed ? '1' : '0')
			url += (url.match(/\?/) ? '&' : '?') + 'unread=' + (unread ? '1' : '0')

			await this.$api.get(url)
				.then(async res => {
					// let user = JSON.parse(JSON.stringify(this.currentUser));
					if (open) {
						// Enhancing short data with opponent ID.
						if (!full) {
							for (let i in res.data.open.data) {
								res.data.open.data[i].opponent_info = {
									id: (
										res.data.open.data[i].user_1_id == this.currentUser.id ?
											res.data.open.data[i].user_2_id :
											res.data.open.data[i].user_1_id
									)
								}
							}
							// // The correct number of inboxes is in short inbox data where all inboxes are loaded.
							// this.setInboxTotal(res.data.open.total, true)
							// this.setInboxTotal(res.data.open.total, false)
						}
						if (page === 1 && !(payload && payload.skip)) {
							if (!full) {
								this.currentUserInboxShort = res.data.open.data;
								this.currentUser.inboxShort = this.currentUserInboxShort;
							} else {
								this.currentUserInbox = res.data.open.data;
								this.currentUser.inbox = this.currentUserInbox;
							}
							// this.setCurrentUser(user)
							this.setInboxClosedPage(0, full)
							this.setInboxClosedTotal(0, full)
						} else {
							if (!full) {
								// this.currentUserInboxShort = this.currentUserInboxShort.concat(res.data.open.data).unique()
								const ids = this.currentUserInboxShort.reduce((total, value) => total.concat([value.id]), [])
								this.currentUserInboxShort.push(...res.data.open.data.filter(item => ids.indexOf(item.id) === -1))
								this.currentUser.inboxShort = this.currentUserInboxShort
								
							} else {
								// this.currentUserInbox = this.currentUserInbox.concat(res.data.open.data).unique()
								const ids = this.currentUserInbox.reduce((total, value) => total.concat([value.id]), [])
								this.currentUserInbox.push(...res.data.open.data.filter(item => ids.indexOf(item.id) === -1))
								this.currentUser.inbox = this.currentUserInbox
								
							}
						
						}
						this.setInboxTotal(res.data.open.total, full)
						this.setInboxPage(res.data.open.current_page, full)
						this.setInboxLoading(false)
					}

					return true
				})
				.catch(async error => {
					this.setInboxLoading(false)
					console.error('getInbox() error.response', error)
					return false
				})
		},
		getOnlineUsers() {
			this.$api.get('/who-online')
				.then(res => this.setWhoOnline(res.data))
				.catch(error => console.error(error.response))
		},
		async getUserByUrl(url) {
			this.setLandingProfileNotFound(false)
			if (!import.meta.env.SSR) {
				this.setLandingProfileLoading(true)
			}
			let is_api_url = this.currentUser?.id ? '/api' : ''
			let request_url = is_api_url + '/user/url/' + url
			return await this.$api.get(request_url)
				.then(res => {
					this.setLandingPageProfile(res.data);
					if (!import.meta.env.SSR) {
						this.setLandingProfileLoading(false);
					}
				})
				.catch(async error => {
					console.error(error, error.response)
					this.setLandingProfileLoading(false);
					if (error.response && error.response.status === 404)
						this.setLandingProfileNotFound(true);
					if (error.response && error.response.status === 401)
						await this.getUserByUrlGuest(url);
				});
		},
		async getUserByUrlGuest(url) {
			this.setLandingProfileNotFound(false);
			if (!import.meta.env.SSR) {
				this.setLandingProfileLoading(true);
			}
			let request_url = '/user/url/' + url;
			return await this.$api.get(request_url)
				.then(res => {
					this.setLandingPageProfile(res.data);
					if (!import.meta.env.SSR) {
						this.setLandingProfileLoading(false);
					}
				})
				.catch(error => {
					this.setLandingProfileLoading(false);
					if (error.response && error.response.status === 404) {
						this.setLandingProfileNotFound(true);
					}
				});
		},
		async getFeedBacks(userProfileId) {
			return await this.loadMoreFeedback(userProfileId, this.showFeedbacks, true)
		},
		async getStats(userProfileId) {
			return await this.$api.get(`/user/${userProfileId}/stats`)
			.then(res => {
				this.totalClients = res.data?.total_clients;
			})
			.catch(err => {
				console.error(err.response)
			})
		},
		async getBestFeedBacks(userProfileId) {
			await this.$api.get(`/user/${userProfileId}/best-feedbacks`)
				.then(res => {
					
					this.userBestFeedBacks = res.data;
					this.userBestFeedBacksPage = 1;
				})
				.catch(err => {
					console.error(err.response)
				})
		},
		async loadMoreFeedback(userProfileId, restart) {
			if (restart) {
				this.userFeedBacksPage = 1;
				this.userFeedBacks = [];
			}
			return await this.$api.get(`/user/${userProfileId}/feedbacks?page=${this.userFeedBacksPage}&per_page=${this.showFeedbacks}&order=`)
				.then(res => {
					this.userFeedBacksPage += 1;
					this.userFeedBacks.push(...res.data.data);
					this.userFeedBacksTotal = res.data.total;
				})
				.catch(err => {
					console.error(err.response)
				})
		},
		async getUserById(id) {
			this.setLandingProfileLoading(true)
			this.setProfileNotFound(false)
			let userProfile = await this.getUserByIdRaw(id)
			this.setUserProfile(userProfile)
			this.setProfileNotFound(!userProfile)
			this.setLandingProfileLoading(false)
			return userProfile
		},
		async getUserByIdRaw(userId) {
			let url = this.$auth?.check()
				? `/api/user/${userId}`
				: `/user/${userId}`

			return await this.$api
				.get(url)
				.then(async (res) => {
					return res.data
				})
				.catch((error) => {
				})
		},
		async getCategoryByName(name) {
			return await this.$api.get(`/user/category/${name}`)
				.then(async res => {
					this.setCategory(res.data);
					this.setCategoryNotFound(false);
					return true;
				})
				.catch(async error => {
					console.error(error, error.response)
					await this.setCategory(null);
					if (error.response?.status === 404) {
						this.setCategoryNotFound(true);
					}
					return false;
				})
		},
		updateMyProfile(changes) {
			this.setUserLoading(true);
			this.$api.put('/api/user', changes)
				.then(res => {
					this.setUserLoading(false);
					

					this.getCurrentUser();

					this.$toast({
						message: res.data.message || 'Your profile has been updated!',
						type: 'success',
						duration: res.data.pending ? 30000 : 5000,
					});

					// Do not redirect if profile updates are pending
					// When profile update is pending, the returned message is long and toast disappears immediately,
					// and advisor can not read the "verification pending" message
					if (!res.data.pending) {
						if (this.isAdvisor) {
							this.$router.push({ path: '/advisor/:url', params: { url: this.currentUser.url } });
						} else {
							this.$router.push('/home');
						}
					}
				})
				.catch(error => {
					console.error(error, error.response)
					this.setUserLoading(false)
					let text = ''
					if (error.response?.data && typeof error.response.data !== 'string') {
						let text = '';
						for (let field in error.response.data) {
							text += error.response.data[field][0]
						}
					} else if (typeof error.response == 'string') {
						text = error.response
					}
					if (error.status == 422 && typeof error.response == 'object') {
						this.currentUser.errors = new Object()
						for (let i in error.response) {
							this.currentUser.errors[i] = error.response[i].join('\n')
						}
						this.currentUser.id = this.currentUser.id
						text = "Error updating profile."
					}
					if (text) {
						this.$toast({
							message: text,
							type: 'error'
						})
					}
				})
		},
		// Internal adding funds to the wallet.
		addFunds(payload, mode = 'paypal', attempt = 1) {
			if (attempt > 1) {
			}

			if (attempt >= 2) {
				alert('We are sorry, but something went wrong. Please try again. If error will appear next time please contact an administrator.');
				return false;
			}

			const url = {
				'paypal': '/api/user/wallet/get-url',
				'stripe': '/api/user/wallet/get-url-stripe',
				'paynl': '/api/user/wallet/get-url-paynl',
			}[mode] ?? '';

			this.setAddFundsLoading(true)
			this.$api.post(url, payload)
				.then(res => {
					this.setAddFundsLoading(false)
					window.location.href = res.data
				})
				.catch(error => {
					this.setAddFundsLoading(false)
					this.addFunds(payload, mode, attempt + 1)
					console.error(error.response)
				})
		},
		// Start adding funds to the wallet.
		submitAddFunds(mode = 'paypal', advisorId, amount, redirectUrl = '') {
			const chat = chatStore()

			let action = ''

			if (this.savedAction) {
				action = this.savedAction
			} else if (this.$router.route.query?.action) {
				action = this.$router.route.query.action
			} else if (this.$router.route.path == '/dashboard/chat' && this.$router.route.query?.mode) {
				let chatMode = this.$router.route.query.mode
				action = chat.getActionByChatMode(chatMode)
			} else if (chat.savedAction) {
				action = chat.savedAction
			}

			let data = {
				redirect_url: redirectUrl,
				action: action,
				param: advisorId || this.savedParam || chat.savedParam || chat.savedAdvisor?.id,
			}
			if (typeof amount === 'object') {
				data.promotion_id = amount.id
			} else {
				data.amount = amount
			}

			this.addFunds(data, mode)
		},

		//get my advisors
		getMyAdvisors(payload) {
			this.setLoadingMyAdvisors(true);
			let page = payload && payload.page ? payload.page : 1;
			let url = '/api/user/my-advisors?page=' + page;
			if (payload && payload.name)
				url += '&name=' + payload.name;
			this.$api.get(url)
				.then(res => {
					this.setMyAdvisors(res.data);
					this.setLoadingMyAdvisors(false);
				})
				.catch(error => {
					console.error(error.response);
					this.setLoadingMyAdvisors(false);
				})
		},
		async getBlocks() {
			await this.$api.get('/api/user/blocks')
				.then(res => this.setCurrentUserBlocks(res.data))
				.catch(async error => {
					console.error(error.response)
				})
		},
		//block user
		blockUser(id) {
			this.setBlockUserLoading(true);
			this.$api.put(`/api/user/${id}/block`, {})
				.then(res => {
					
					this.setBlockUserLoading(false);
				})
				.catch(error => {
					console.error(error.response);
					this.setBlockUserLoading(false);
				})
		},
		//unblock user
		unBlockUser(id) {
			this.setBlockUserLoading(true);
			this.$api.put(`/api/user/${id}/unblock`, {})
				.then(res => {
					
					this.setBlockUserLoading(false);
				})
				.catch(error => {
					console.error(error.response);
					this.setBlockUserLoading(false);
				})
		},
		async getCategories() {
			return await this.$api.get('/categories')
				.then(res => {
					this.setCategories(res.data);
				})
				.catch(async error => {
				})
		},
		async getAdvisorsIndexList(for_slider = false) {
			let url = this.$auth?.check()
				? '/api/user/search/list'
				: '/search/list';
			if (for_slider) {
				url += '?for_slider=1'
			}
			await this.$api.get(url)
				.then(async res => {
					this.advisorsIndexList = res.data;
				})
				.catch(async err => {
				})
		},
		async getMyChattedAdvisors(page = 1, overwrite = false) {
			// Skipping if repeating cached query.
			if (!overwrite && this.myChattedAdvisors?.length && page == this.myChattedAdvisorsCurrentPage) {
				return true;
			}
			this.myChattedAdvisorsCurrentPage = page
			let url = (
				'/api/user/advisors/chatted'
			) + '?page=' + this.myChattedAdvisorsCurrentPage
			await this.$api.get(url)
				.then(async res => {
					if (overwrite) {
						this.myChattedAdvisors = res.data.data
					} else {
						this.myChattedAdvisors.push(...res.data.data);
					}
					this.myChattedTotalAdvisors = res.data.total;
					this.myChattedAdvisorsCurrentPage = res.data.current_page;
				})
				.catch(error => {
					console.error(error.response);
				})
		},
		async getNotMyChattedAdvisors(page = 1, overwrite = false) {
			// Skipping if repeating cached query.
			if (!overwrite && this.notMyChattedAdvisors?.length && page == this.notMyChattedAdvisorsCurrentPage) {
				return true;
			}
			this.notMyChattedAdvisorsCurrentPage = page
			let url = (
				'/api/user/advisors/not-chatted'
			) + '?page=' + this.notMyChattedAdvisorsCurrentPage
			await this.$api.get(url)
				.then(async res => {
					if (overwrite) {
						this.notMyChattedAdvisors = res.data.data
					} else {
						this.notMyChattedAdvisors.push(...res.data.data);
					}
					this.notMyChattedTotalAdvisors = res.data.total;
					this.notMyChattedAdvisorsCurrentPage = res.data.current_page;
				})
				.catch(error => {
					console.error(error.response);
				})
		},
		async getTopAdvisors(page = 1, overwrite = false) {
			// Skipping if repeating cached query.
			if (!overwrite && this.topAdvisors?.length && page == this.topAdvisorsCurrentPage) {
				return true;
			}
			this.topAdvisorsCurrentPage = page
			let url = (
				!import.meta.env.SSR && this.$auth?.check()
					? '/api/user/advisors/top'
					: '/user/advisors/top'
			) + '?page=' + this.topAdvisorsCurrentPage
			await this.$api.get(url)
				.then(async res => {
					if (overwrite) {
						this.topAdvisors = res.data.data
					} else {
						this.topAdvisors.push(...res.data.data);
					}
					this.topTotalAdvisors = res.data.total;
					this.topAdvisorsCurrentPage = res.data.current_page;
				})
				.catch(error => {
					console.error(error.response);
				})
		},
		async getOnlineAdvisors(page = 1, overwrite = false) {
			// Skipping if repeating cached query.
			if (!overwrite && this.onlineAdvisors?.length && page == this.onlineAdvisorsCurrentPage) {
				return true;
			}
			this.onlineAdvisorsCurrentPage = page
			let url = (
				!import.meta.env.SSR && this.$auth?.check()
					? '/api/user/advisors/online'
					: '/user/advisors/online'
			) + '?page=' + this.onlineAdvisorsCurrentPage
			await this.$api.get(url)
				.then(res => {
					if (overwrite) {
						this.onlineAdvisors = res.data.data
					} else {
						this.onlineAdvisors.push(...res.data.data);
					}
					this.onlineTotalAdvisors = res.data.total;
					this.onlineAdvisorsCurrentPage = res.data.current_page;
				})
				.catch(error => {
					console.error(error.response);
				})
		},
		// async onlineAdvisorsListRefresh(take) {
		// 	const url = (this.$auth?.check() ? '/api' : '')
		// 		+ '/user/advisors/online-by-count-home';
		// 	// this.isUsersLoading = true;
		// 	// const take = this.onlineAdvisors.length < 6 ? 6 : this.onlineAdvisors.length;
		// 	this.$api.post(url, { take })
		// 		.then(res => {
		// 			this.onlineAdvisors = res.data.data;
		// 			this.onlineTotalAdvisors = res.data.total;
		// 			this.onlineAdvisorsCurrentPage = Math.ceil(res.data.data.length / 6);
		// 			// this.isUsersLoading = false;
		// 		})
		// 		.catch(error => {
		// 			console.error(error, error.response);
		// 			// this.isUsersLoading = false;
		// 		})
		// },
		async getNewAdvisors(page = 1, overwrite = false) {
			// Skipping if repeating cached query.
			if (!overwrite && this.newAdvisors?.length && page == this.newAdvisorsCurrentPage) {
				return true;
			}
			this.newAdvisorsCurrentPage = page
			let url = (
				!import.meta.env.SSR && this.$auth?.check()
					? '/api/user/advisors/new'
					: '/user/advisors/new'
			) + '?page=' + this.newAdvisorsCurrentPage
			await this.$api.get(url)
				.then(res => {
					if (overwrite) {
						this.newAdvisors = res.data.data
					} else {
						this.newAdvisors.push(...res.data.data);
					}
					this.newTotalAdvisors = res.data.total;
					this.newAdvisorsCurrentPage = res.data.current_page;
				})
				.catch(error => {
					console.error(error.response);
				})
		},
		//get category advisors
		async getCategoryAdvisors(category_name, page = 1, overwrite = false) {
			if (!category_name) {
				return false;
			}
			// Skipping if repeating cached query.
			if (!overwrite && this.categoryAdvisors?.length && page == this.categoryCurrentPage) {
				return true;
			}
			this.categoryCurrentPage = page
			let url = (
				!import.meta.env.SSR && this.$auth?.check()
					? '/api/user/advisors/category/'
					: '/user/advisors/category/'
			) + category_name + '?page=' + this.categoryCurrentPage
			await this.$api.get(url)
				.then(async res => {
					if (overwrite) {
						this.categoryAdvisors = res.data.advisors.data;
					} else {
						this.categoryAdvisors.push(...res.data.advisors.data);
					}
					this.categoryCurrentPage = res.data.advisors.current_page;
					this.categoryTotalAdvisors = res.data.advisors.total;
				})
				.catch(error => {
					console.error(error.response);
				})
		},
		// Returns fee with discount applied.
		calculateDiscountedFee(fee, advisor) {
			let discount = advisor?.discount ?? 0
			const custom_discount = advisor?.custom_discount ?? 0
			// Normally advisor has only discount field. 
			// This is  to support edit profile pages it has both and discount is a dropdown with % and 'custom' value.
			if (discount === 'custom') {
				discount = custom_discount
			} 
			return (fee * (1 - discount / 100)).toFixed(2)
		},
		// Returns minimum fee of the advisor considering which chat modes are available.
		minPerMinuteFee(advisor) {
			let fee = NaN
			if (advisor.text_enabled) {
				fee = advisor.per_minute_fee_text
			}
			if (advisor.audio_enabled) {
				if (fee === NaN) {
					fee = advisor.per_minute_fee_audio
				} else {
					fee = Math.min(fee, advisor.per_minute_fee_audio)
				}
			}
			if (advisor.video_enabled) {
				if (fee === NaN) {
					fee = advisor.per_minute_fee_video
				} else {
					fee = Math.min(fee, advisor.per_minute_fee_video)
				}
			}
			return fee
		},
		//in blocked advisor
		isBlockedByCurrentUser(opponentId) {
			if (
				!this.currentUser ||
				!this.currentUser.blocks ||
				opponentId === this.currentUser.id
			) {
				return false
			}
			let state = false
			this.currentUser.blocks.forEach(block => {
				if (block.blocker_id === opponentId || block.blocked_user_id === opponentId) {
					state = true;
				}
			})
			return state;
		},
		getUnreadInbox() {
			if (!this.currentUser || !this.currentUser.inboxShort) {
				return 0
			}
			let unread = 0
			this.currentUser.inboxShort.forEach(inbox => {
				unread += (inbox.unread_count > 0 ? 1 : 0)
			})
			return unread
		},

		//check if user can unblock advisor (advisor blocked by user, not vice versa)
		canUnblock(currentUser, userId) {
			if (!currentUser || !currentUser.blocks)
				return false;
			let state = false;
			currentUser.blocks.forEach(block => {
				if (block.blocker_id === currentUser.id && block.blocked_user_id === userId)
					state = true;
			});
			return state;
		},

		//format accuracy (100.00 => 100, 99.5 => 99.50)
		formatAccuracy(accuracy) {
			return parseFloat(accuracy) === Math.floor(accuracy) ? parseInt(accuracy) : Number(accuracy).toFixed(2);
		},

		loadPlans(advisor) {
			this.plans = []
			this.plansLoading = true
		
			return new Promise((resolve, reject) => {
				this.$api.get(`/api/user/advisor/${advisor.id}/subscriptions/plans`)
					.then(res => {
						this.plans = res.data.plans;
						resolve(this.plans)
					})
					.catch(error => {
						if (error.response?.message) {
							this.$toast({
								message: '<b>Error: </b>' + error.response.message,
								type: 'error',
							})
						}
						reject(error)
					})
					.finally(() => this.plansLoading = false)
			})
		},

		subscribe(advisor, plan) {
			if (Number(this.currentUser.amount) < Number(plan.price)) {
				const inbox = inboxStore()
				inbox.setNotEnoughMoneyInbox(true)
				this.setChargeAmount(plan.price)
				this.saveAction('subscribe')
				this.saveParam(plan.id)
				this.saveRedirectUrl(`advisor/${advisor.url}/subscribe`)

				return false
			}

			plan.advisor = advisor
			this.subscribingToPlan = plan
			this.isActiveSubscriptionApproval = true
		},
		cancelSubscription() {
			this.subscribeLoading = false
			this.isActiveSubscriptionApproval = false
			this.subscribingToPlan = null
		},
		confirmSubscription() {
			if (!this.isActiveSubscriptionApproval || !this.subscribingToPlan) {
				return
			}

			this.subscribeLoading = true

			this.$api.post(`/api/user/advisor/${this.subscribingToPlan.advisor.id}/subscriptions/subscribe`, {
				plan_id: this.subscribingToPlan.id,
			}).then(({ data }) => {
				this.getCurrentUser()

				this.isActiveSubscriptionApproval = false
				this.subscribingToPlan = null

				if (data.inbox) {
					// Workaround to give user time to read a real-time notification, and then redirect
					setTimeout(() => this.$router.push({ path: '/dashboard/inbox', query: { id: data.inbox } }), 2000)
				}
			}).catch(error => {
				if (error.response) {
					this.$toast({
						message: '<b>Error: </b>' + (error.response.message || error.response),
						type: 'error'
					});
				}
			}).finally(() => this.subscribeLoading = false)
		},
		isSubscribedToAdvisor(advisor) {
			return this.currentUser.subscriptions?.filter(subscription => subscription.advisor?.id === advisor.id).length > 0
		},
		formatPlanFeatures(plan) {
			// Always to have the same order in features, and make "high priority" first in the list
			const order = ['high_priority', 'free_minutes', 'discount']

			const featuresArray = Object.entries(plan.features)

			// Sort the array based on the predefined order
			featuresArray.sort((a, b) => {
				const indexA = order.indexOf(a[0]) !== -1 ? order.indexOf(a[0]) : order.length
				const indexB = order.indexOf(b[0]) !== -1 ? order.indexOf(b[0]) : order.length

				return indexA - indexB
			})

			// Convert the sorted array back into an object if needed
			const sortedFeatures = featuresArray.reduce((acc, [key, value]) => {
				acc[key] = value
				return acc
			}, {})

			const resolvers = {
				free_minutes: value => `${value} free minutes per ${plan.interval}`,
				discount: value => `${value}% extra discount on the per-minute fee of all future sessions`,
				high_priority: 'High Priority in Inbox',
			}

			const features = []

			Object.keys(sortedFeatures).forEach(key => {
				const value = plan.features[key]

				if (!value) {
					return
				}

				const resolver = resolvers[key] || null

				if (typeof resolver === 'function') {
					features.push(resolver(value))

					return
				}

				features.push(resolver || key
					.split('_')
					.map(word => word.charAt(0).toUpperCase() + word.slice(1))
					.join(' '))
			})

			return features
		},
		saveAction(action) {
			this.savedAction = action;
		},
		saveParam(param) {
			this.savedParam = param;
		},
		saveRedirectUrl(url) {
			this.savedRedirectUrl = url;
		},
		setChargeAmount(amount) {
			this.chargeAmount = amount ? Number(amount) : null;
		},
		
		resendVerificationEmail() {
			return this.$api.post(`/api/user/email/resend`)
		},
	}
});
