<template>
  <div
    class="c-textarea"
    :class="[
      {
        'is-disabled': props.disabled,
        'is-invalid': props.invalid,
      }]"
  >
    <div v-if="$slots.prefix" class="c-textarea__prefix">
      <slot name="prefix" :value="value" />
    </div>
    <textarea
      ref="textarea"
      v-model="value"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      v-bind="$attrs"
      @keydown.enter.exect.prevent="emit('enterPressed')"
      rows="1"
    />
    <div v-if="$slots.suffix || props.clearable" class="c-textarea__suffix">
      <slot name="suffix" :value="value" />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed, onMounted, ref, nextTick, watch, defineExpose,
} from 'vue';
import autosize from 'autosize';

const props = withDefaults(defineProps<{
  modelValue: string,
  placeholder?: string,
  disabled?: boolean,
  invalid?: boolean,
  clearable?: boolean,
  autoResize?: boolean,
}>(), {
  placeholder: '',
  disabled: false,
  invalid: false,
  clearable: false,
  autoResize: true,
});

const emit = defineEmits(['update:modelValue', 'enterPressed']);

const value = computed<string | number>({
  get() {
    return props.modelValue;
  },
  set(val: string | number) {
    emit('update:modelValue', val);
  },
});

const textarea = ref<any>(null);
defineExpose({ textarea });

onMounted(() => {
  if (props.autoResize && textarea.value) {
    autosize(textarea.value);
  }
});

watch(value, async () => {
  if (textarea.value && props.autoResize) {
    textarea.value.dispatchEvent(new Event('autosize:destroy'));
    await nextTick();
    autosize(textarea.value);
  }
});
</script>

<script lang="ts">
export default {
  name: 'SeTextarea',
};
</script>
