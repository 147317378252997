<template>
  <div :class="props.isDeclined ? 'bg-error-100' : scheme[props.chatAction]?.bg" class="text-white rounded-full p-6 inline-block">
    <se-icon :size="props.size" :name="scheme[props.chatAction]?.icon" />
  </div>
</template>

<script lang="ts" setup>
import SeIcon from '../icon/Icon';

const props = withDefaults(defineProps<{
  size?: number,
  chatAction?: string
  isDeclined?: boolean
}>(), {
  size: 20,
  chatAction: 'text',
  isDeclined: false
});

const scheme = {
  video: {
    bg: '!bg-primary-100',
    text: 'text-primary-100',
    icon: props.isDeclined? 'video-off' : 'video',
  },
  audio: {
    bg: '!bg-feature-100',
    text: 'text-feature-100',
    icon: props.isDeclined? 'phone-off' : 'phone-call',
  },
  text: {
    bg: '!bg-love-100',
    text: 'text-love-100',
    icon: props.isDeclined? 'message-circle-off' : 'message-circle',
  },
};
</script>

<script lang="ts">
export default {
  name: 'SeChatOption',
};
</script>
