import config from "@/config";

export default function useImageSrc() {
  const imageSrc = (src: string) => {
    if (src.startsWith('http')) {
      return src;
    }
    return `${config.api.path}${src}`;
  }

  return {imageSrc}
}