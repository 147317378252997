<template>
  <se-popup v-if="isVisible" classes="sm:w-[448px]" title="Send Message" :show-close="true" @close="closeMessageModal">
    <div class="flex flex-col items-start justify-center gap-16">
      <div v-if="savedRecepient" class="flex flex-column items-center justify-center gap-16 w-full my-24 ">
        <se-avatar
            :id="savedRecepient.id"
            :size="68"
            :name="savedRecepient.name"
            :src="savedRecepient.avatar"
        />
        <p class="text-h-m pt-16 text-center font-bold">{{ savedRecepient.name }}</p>
      </div>
      <div class="feedback-message w-full">
        <p class="text-b-xs text-neutral-60 mb-8 font-medium">Type your message and click Send button</p>
        <se-textarea v-model="message" class="min-h-[72px]" placeholder="Type your message here..." />
      </div>
    </div>
    <template #footer>
      <se-button @click="sendMessage(message)" :loading="sendingMessage" :disabled="!message" class="w-full">
        Send Message
      </se-button>
    </template>
  </se-popup>
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {storeToRefs} from "pinia";
import {inboxStore} from "@/src/store/inbox";
import useInbox from "@/src/composables/useInbox";
import SePopup from "@/src/shared/ui-kit/popup/Popup.vue";
import SeButton from "@/src/shared/ui-kit/button/Button.vue";
import SeTextarea from "@/src/shared/ui-kit/textarea/Textarea.vue";
import SeAvatar from "@/src/shared/ui-kit/avatar/Avatar.vue";

const {sendMessage} = useInbox();

const inbox = inboxStore()
const {sendingMessage, savedRecepient, isActiveMessageModal} = storeToRefs(inbox)
const {closeMessageModal} = inbox

const message = ref<string>('')

const isVisible = computed(() => isActiveMessageModal.value)
</script>

<script lang="ts">
export default {
  name: 'SeSendMessagePopup',
}
</script>


<style scoped lang="scss">

</style>