<template>
<!--  <div class="c-spinner" :style="{ '&#45;&#45;se-spinner-size': props.size }" />-->
  <svg class="c-spinner" :style="{ '--se-spinner-size': props.size }" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
    <circle class="spinner-bg" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{
  size?: string,
}>(), {
  size: '2rem',
});

</script>

<script lang="ts">
export default {
  name: 'SeSpinner',
};
</script>
