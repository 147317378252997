import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { Notification } from '@/src/types/notifications';

const useNotificationStore = defineStore('notification', () => {
  const notifications = ref<Notification[]>([]);

  function setNotifications(newNotifications: Notification[]) {
    notifications.value = newNotifications;
  }

  function addNotifications(newNotifications: Notification[]) {
    notifications.value = [...notifications.value, ...newNotifications];
  }

  function readNotification(id) {
    notifications.value.forEach(item => {
      if (id && item.id === id && item.is_unread) {
        item.is_unread = false;
      }
    });
  }

  return {
    notifications,
    setNotifications,
    addNotifications,
    readNotification
  };
});

export function useNotificationsStore() {
  return useNotificationStore();
}
