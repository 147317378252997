import Form from "@/src/helpers/form-validation";
import {getGlobalProperties} from "@/src/shared/helpers/getGlobalProperties";
import {useFeedbacksStore} from "@/src/store/feedbacks";
import {storeToRefs} from "pinia";

export default function useFeedbacks() {
    const { $api, $router, $toast } = getGlobalProperties()
    const feedbacks = useFeedbacksStore();
    const { setSavingFeedbackLoader, resetFeedbackForm } = feedbacks;
    const { feedbackForm } = storeToRefs(feedbacks);

    if (!$api) {
        console.error("API instance is not available");
    }

    //save feedback
    const saveFeedBack = (redirectUrl = '/dashboard/history') => {
        setSavingFeedbackLoader(false)
        const params = {
            chat_id: feedbackForm.value.chatId,
            feedback: feedbackForm.value.message,
            feedback_rate: feedbackForm.value.rating
        }
        $api.post('/api/chat/' + feedbackForm.value.chatId + '/feedback', params)
            .then( () => {
                $router.push(redirectUrl);
                $toast({
                    message: 'Feedback has been saved. Thank you for your opinion! ' +
                        'You can update your feedback any time within 7 days after chat session.',
                    type: 'success'
                });
                setSavingFeedbackLoader(false)
                resetFeedbackForm()
            })
            .catch(error => {
                console.error(error.response);
                $router.push(redirectUrl);
                if (error.response)
                    $toast({
                        message: error.response,
                        type: 'error'
                    });
                setSavingFeedbackLoader(false)
                resetFeedbackForm()
            })
    }

    return {
        saveFeedBack
    };
}
