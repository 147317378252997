<template>
  <se-popup
    title="Top up your balance"
    classes="sm:w-[448px]"
    :show-close="true"
    @close="emits('close')"
  >
    
    <div class="py-16">
      <se-info-box
        title="Not enough funds"
        text="Please add funds to use this offer. Unused funds will be returned to your account."
      />
      
      <div v-if="props.advisor">
        <div class="flex flex-column items-center justify-center w-full my-24">
          <se-avatar-chat-option
            :id="props.advisor.id"
            :size="68"
            :name="props.advisor.name"
            :src="props.advisor.avatar"
            :chat-action="props.chatMode"
          />
          <p class="text-h-m pt-16 text-center font-bold">{{ props.advisor.name }}</p>
        </div>
        
        <add-funds-buttons
          :advisor-id="props.advisor.advisorId ? props.advisor.advisorId : props.advisor.id"
          :chat-mode="props.chatMode"
          :large-mode="true"
          :single-mode="false"
        />
      </div>
    </div>
  </se-popup>
</template>

<script setup lang="ts">
import {onMounted} from "vue";
import {storeToRefs} from "pinia";
import {usersStore} from "@/src/store/users";
import AddFundsButtons from "@/src/components/AddFundsButtons.vue";
import SeAvatarChatOption from "@/src/shared/ui-kit/avatar-chat-option/AvatarChatOption.vue";
import SePopup from "@/src/shared/ui-kit/popup/Popup.vue";
import SeInfoBox from "@/src/shared/ui-kit/info-box/InfoBox.vue";

const props = defineProps<{
  advisor: any,
  chatMode: string,
}>();

const emits = defineEmits(['close']);

const users = usersStore()
const {currentUser} = storeToRefs(users)
const {getCurrentUser} = users

onMounted(() => {
  getCurrentUser()
})
</script>

<script lang="ts">
export default {
  name: 'SeTopUpBalancePopup',
};
</script>