<template>
  <se-popup v-if="visible"
            :classes="'sm:w-[720px] py-24'"
            :show-close="false"
            :bg-image="actionType !== 'text' ? imageSrc(savedAdvisor.avatar) : ''"
  >
    <se-info-box
        text="Connecting you to your private session. If the advisor asks for off-site payment or your personal info,
        do not share it and report it to us."
    />

    <div class="flex flex-column items-center justify-center w-full">
      <div class="relative bg-primary-10 rounded-full flex justify-center items-center my-32 sm:my-64">
        <div class="absolute top-0 left-0 w-full h-full rounded-full animation-pulse"></div>
        <se-avatar :size="120" :id="savedAdvisor.id" :src="savedAdvisor.avatar" :name="savedAdvisor.name" class="sm:hidden"></se-avatar>
        <se-avatar :size="140" :id="savedAdvisor.id" :src="savedAdvisor.avatar" :name="savedAdvisor.name" class="hidden sm:visible sm:block"></se-avatar>
      </div>
      <div class="flex flex-col gap-16 items-center" :class="{'text-neutral-0': actionType !== 'text'}">
        <p class="text-h-l font-bold text-center max-w-[90%]">Connecting to <span class="capitalize">{{actionType}}</span> Chat with {{savedAdvisor.name}}...</p>
        <p class="flex flex-col items-center text-b-xs">
          Time remaining to accept your offer
          <span class="text-h-l font-bold">{{acceptSeconds?.toString().toHHMMSS(true)}}</span>
        </p>
      </div>
    </div>
    <template #footer>
      <se-button icon-only class="!bg-error-100 !mx-auto !flex mt-32"
                 size="xlarge"
                 @click="cancelChat"
                 :loading="canceling"
      >
        <se-icon name="x"></se-icon>
      </se-button>
    </template>
  </se-popup>
</template>

<script setup lang="ts">
import {storeToRefs} from "pinia";
import {computed, ref, watch} from 'vue';
import {chatStore} from "@/src/store/chat";
import {usersStore} from "@/src/store/users";
import SeButton from "@/src/shared/ui-kit/button/Button.vue";
import SePopup from "@/src/shared/ui-kit/popup/Popup.vue";
import SeIcon from "@/src/shared/ui-kit/icon/Icon.vue";
import SeInfoBox from "@/src/shared/ui-kit/info-box/InfoBox.vue";
import SeAvatar from "@/src/shared/ui-kit/avatar/Avatar.vue";
import {TimeCalculations} from "@/src/helpers/timeCalculations";
import useImageSrc from "@/src/shared/helpers/useImageSrc";
import {useStartChatPopupsStore} from "@/src/store/startChatPopups";

const ACCEPT_TIMER_TIMEOUT = 50
const {imageSrc} = useImageSrc()

const popupStore = useStartChatPopupsStore()
const {chatApprovalActionType} = storeToRefs(popupStore)

const chat = chatStore()
const {savedAdvisor, canceling} = storeToRefs(chat)
const {cancelChat} = chat

const userStore = usersStore()
const {currentUser, isUser} = storeToRefs(userStore)

const visible = computed(() => savedAdvisor.value && isUser.value && waitingForChatConfirmation.value)
const waitingForChatConfirmation = computed(() => !!(currentUser.value?.active_chat && currentUser.value?.active_chat.status === 'pending'))

const actionType = computed(() => {
  if (chatApprovalActionType.value) {
    return chatApprovalActionType.value
  }
  return currentUser.value?.active_chat ? currentUser.value.active_chat.mode : 'text'
});

watch(() => waitingForChatConfirmation.value, (newValue) => {
  if (newValue && isUser.value) {
      startUserCallStartTimeoutTimer()
  }
})

const acceptSeconds = ref();
// Timer with call accept timeout for user (just output numbers).
const startUserCallStartTimeoutTimer = () => {
  let timeNow = TimeCalculations.rawAddTime(TimeCalculations.getCurrentTime(), 0, 'seconds')
  let seconds = TimeCalculations.getSecondsFromDate(currentUser.value.active_chat.created_at, timeNow)
  
  acceptSeconds.value = ACCEPT_TIMER_TIMEOUT - seconds;
  let initialized = false
  let interval = setInterval( () => {
    // Stopping timer if dialog is about to be closed.
    if (!initialized && !waitingForChatConfirmation.value || acceptSeconds.value <= 0) {
      clearInterval(interval)
      return
    }
    
    initialized = true
    acceptSeconds.value -= 1
  }, 1000)
}
</script>

<script lang="ts">
export default {
  name: 'SeConnectingAdvisorPopup',
};
</script>

<style lang="scss">

.animation-pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 1;
    box-shadow: 0 0 0 0 var(--se-color-primary-100);
  }

  70% {
    transform: scale(1);
    opacity: 0;
    box-shadow: 0 0 0 20px var(--se-color-primary-100);
  }

  100% {
    opacity: 0;
    transform: scale(0.9);
  }
}
</style>