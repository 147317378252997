import {HowItWorksConfig} from "../../_core/types/theme/HowItWorks.type";

const howItWorks: HowItWorksConfig =  {
    title: 'How MysticWork Works?',
    body: 'A brief step-by-step guide to make the most of your experience with your MysticWork psychic advisor.',
    steps: [
        {
            title: 'Select Your Advisor',
            description: 'Choose a psychic that resonates most with you to ensure you receive the best experience. Tailored to meet your needs, all sessions can be customized to your preferences and availability, whether you’re looking for a quick insight or a more in-depth exploration, our psychics are vetted and ready to serve you.'
        },
        {
            title: 'Sign Up With Ease',
            description: 'Sign up hassle-free by using an existing social media account or creating a new one with your email address. Choose from a variety of convenient and secure payment methods to make the process smooth and stress-free.'
        },
        {
            title: 'Connect and enjoy',
            description: 'Connect and Enjoy Initiate the chat with your selected psychic advisor and experience the energy connection unfold. Share your questions, thoughts, and curiosities as you embark on a journey of insight and revelation. Enjoy your first 3 minutes for free, using this time to connect with your advisor and feel the energy before seamlessly transitioning into a paid chat if you wish to continue your exploration. With these simple steps, your psychic session becomes a personalized and accessible experience, allowing you to navigate the mystical realms and uncover the insights that awaits you.'
        },
    ],
    topAdvisors: {
        title: 'Check Our Top Advisors',
        ctaText: 'Show All Advisors'
    },
    seo: {
        title: 'How it works - MysticWork',
        description: 'Choose your advisor > Click \'Chat\' > Enjoy your reading.',
    },
};

// @ts-ignore
export default howItWorks;
