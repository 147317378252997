<template>
  <div :class="{ App: true, 'is-fixed': IS_OLD, 'is-ie': isIE }">
    <noscript>
      Your browser does not have JS enabled, you are still able to browse the website but you would not be able to
      access advanced features such as editing or loggin-in.
    </noscript>

    <Layout ref="layout">
      <slot />
    </Layout>

    <se-add-edit-feedback-popup :can-change="true" />
    <StayOnlineModal />

    <se-send-message-popup />

    <se-not-enough-money-popup
      v-if="!['/paypal/success', '/stripe/success', '/paynl/success'].includes($route.path)"
      :advisor="savedAdvisor"
    />

    <BootstrapModal
      v-if="isAdvisor && waitingForChatConfirmation"
      :isActive="true"
      :timer="acceptSeconds"
      heading="Time remaining to accept"
      :showClose="false"
    >
      <section>
        <h3 class="text-center">
          <avatar
            class="display-inline-block mr-1"
            :username="currentUser.active_chat.client.name"
            :src="currentUser.active_chat.client.avatar"
            :size="50"
          />
          <span>{{ currentUser.active_chat.client.name }}</span>
          <b v-if="currentUser.active_chat && currentUser.active_chat.is_free">[free chat]</b>
        </h3>

        <div>
          <div class="row">
            <div class="col-6 text-right">Is requesting:</div>
            <div class="col-6 font-size-large">
              <span v-if="currentUser.active_chat.mode == 'text'"><font-awesome-icon icon="comments" /> Text chat</span>
              <span v-else-if="currentUser.active_chat.mode == 'audio'"
                ><font-awesome-icon icon="phone" /> Voice call</span
              >
              <span v-else-if="currentUser.active_chat.mode == 'video'"
                ><font-awesome-icon icon="video" /> Video call</span
              >
            </div>
          </div>
        </div>

        <div>
          <div class="row">
            <div class="col-6 text-right">Per minute fee:</div>
            <div class="col-6 text-bold-700">
              <p class="mb-0">
                <s
                  class="text-danger"
                  v-if="
                    currentUser.active_chat.actual_fee &&
                    currentUser.active_chat.per_minute_fee != currentUser.active_chat.actual_fee
                  "
                  >${{ currentUser.active_chat.actual_fee }}/min</s
                ><span class="text-success"> ${{ currentUser.active_chat.per_minute_fee }}/min</span>
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="
            (currentUser.active_chat &&
              !currentUser.active_chat.is_free &&
              Number(currentUser.active_chat.free_minutes) > 0) ||
            (currentUser.active_chat && currentUser.active_chat.penalty_minutes)
          "
        >
          <div class="row">
            <div class="col-6 text-right">Free minutes:</div>
            <div class="col-6 text-bold-700">
              <p class="text-success">
                {{ currentUser.active_chat.free_minutes + currentUser.active_chat.penalty_minutes }} minutes
              </p>
            </div>
          </div>
        </div>
      </section>
      <template v-slot:buttons>
        <button
          type="button"
          class="btn btn-cancel btn-red"
          :class="{ 'is-loading': rejectChatLoading }"
          :disabled="acceptChatLoading"
          @click="rejectChatRequest"
        >
          Reject
        </button>
        <button
          type="button"
          class="btn btn-success btn-green font-size-large font-weight-bold"
          :class="{ 'is-loading': acceptChatLoading }"
          :disabled="rejectChatLoading"
          @click="acceptChatRequest"
        >
          Accept
        </button>
      </template>
    </BootstrapModal>

    <!-- Start chat popups -->
    <se-start-chat-popup @testAudio="$refs.audioCallTest.start()" @testVideo="$refs.videoCallTest.start()" />
    <se-connecting-advisor-popup />
    <se-missed-chat-popup />

    <BootstrapModal
      :isActive="isActiveSubscriptionApproval"
      :submitLoading="subscribeLoading"
      v-if="isUser"
      @cancel="cancelSubscription"
      @submit="confirmSubscription"
      submitText="Subscribe"
      :showHeader="false"
    >
      <section class="popup-user-info">
        <h3 class="text-center" v-if="subscribingToPlan.advisor !== null">
          <avatar
            class="display-inline-block mr-2"
            :username="subscribingToPlan.advisor.name"
            :src="subscribingToPlan.advisor.avatar"
            :size="25"
          />
          <span>{{ subscribingToPlan.advisor.name }}</span>
        </h3>
        <p class="text-center spaced">
          You are subscribing to <strong>{{ subscribingToPlan.name }}</strong> plan for
          <strong>${{ subscribingToPlan.price }}/{{ subscribingToPlan.interval }}</strong>
        </p>
        <p class="text-center spaced small text-muted">
          Subscription won't be renewed automatically. You have to renew it manually using your balance.
        </p>
      </section>
    </BootstrapModal>

    <se-continue-with-same-advisor-popup
      v-if="!!carryOnWithCurrentAdvisorChat && carryOnWithCurrentAdvisorChat.id"
      :chatInfo="carryOnWithCurrentAdvisorChat"
      @close="
        setFeedbackFormChatId(carryOnWithCurrentAdvisorChat.id);
        carryOnWithCurrentAdvisorChat = null;
      "
      @submit="submitCarryOnWithAdvisor"
    />

    <TestCall ref="videoCallTest" :audioMode="true" :videoMode="true" />
    <TestCall ref="audioCallTest" :audioMode="true" :videoMode="false" />

    <PhoneNumberConfirmation />
    <VerifyIdentityModal />
    <CookieWarning />

    <AlertModal ref="alert" />
    <FeedbacksModal />
  </div>
</template>

<script>
import { toHHMMSS } from '@/src/helpers/toHHMMSS';

String.prototype.toHHMMSS = toHHMMSS;

import { getClientData } from '@/src/helpers/checkClientData';

if (!import.meta.env.SSR) {
  window.addEventListener('vite:preloadError', () => {
    window.location.reload();
  });

  function checkOldBrowser() {
    var browser_data = getClientData();
    window.CLIENT_INFO = browser_data;
    if (
      (browser_data.browser === 'Chrome' && browser_data.browser_version < 29) ||
      (browser_data.browser === 'Opera' && browser_data.browser_version < 27) ||
      (browser_data.browser == 'Microsoft Internet Explorer' && browser_data.browser_version < 11) ||
      (browser_data.browser === 'Safari' && browser_data.browser_version < 9) ||
      (browser_data.browser === 'Firefox' && browser_data.browser_version < 30)
    ) {
      document.body.removeChild(document.getElementById('app'));
      var element = document.createElement('div');
      element.style.height = document.documentElement.clientHeight + 'px';
      element.style.width = document.documentElement.clientWidth + 'px';
      element.className += 'old-browser-panel ';
      element.innerHTML =
        '<div class=" old-browser-panel" style="height: ' +
        element.style.height +
        '; width: ' +
        element.style.width +
        ';"><h2>You are seeing this page because you are using a unsupported browser.<br>Currently we supporting:</h2><table class="browsers-wrapper"><tr><td><img src="/static/images/browser_icons/chrome.svg" class="browser-icon" alt=""></td><td>29 and higher</td></tr><tr><td><img src="/static/images/browser_icons/safari.svg" class="browser-icon" alt=""></td><td>9 and higher</td></tr><tr><td><img src="/static/images/browser_icons/opera.svg" class="browser-icon" alt=""></td><td>27 and higher</td></tr><tr><td><img src="/static/images/browser_icons/firefox.svg" class="browser-icon" alt=""></td><td>30 and higher</td></tr><tr><td><img src="/static/images/browser_icons/ie.svg" class="browser-icon" alt=""></td><td>11 and higher</td></tr></table><p><a href="http://outdatedbrowser.com/en" class="button is-warning is-large">Please update your browser now</a></p></div>';
      element.innerHTML += '';
      document.body.appendChild(element);
    }
  }

  try {
    checkOldBrowser();
  } catch (e) {
    console.error(e);
  }
}

import { mapState, mapActions } from 'pinia';
import { usersStore } from '@/src/store/users';
import { inboxStore } from '@/src/store/inbox';
import { chatStore } from '@/src/store/chat';
import { presenceStore } from '@/src/store/presence';
import { rootStore } from '@/src/store/root';
import { TimeCalculations } from '@/src/helpers/timeCalculations';
import _ from '@/src/helpers/arrayHelpers';
import config from '@/config';

import Layout from '@/src/layouts/Layout.vue';
import AlertModal from '@/src/components/AlertModal.vue';
import Avatar from '@/src/components/Avatar.vue';
import StayOnlineModal from '@/src/components/StayOnlineModal.vue';
import BootstrapModal from '@/src/components/BootstrapModal.vue';
import FeedbacksModal from '@/src/components/FeedbacksModal.vue';
import TestCall from '@/src/components/TestCall.vue';
import PhoneNumberConfirmation from '@/src/components/PhoneNumberConfirmation.vue';
import CookieWarning from '@/src/components/CookieWarning.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faComments, faVideo, faPhone } from '@fortawesome/free-solid-svg-icons';

library.add(faComments, faVideo, faPhone);

import VerifyIdentityModal from '@/src/components/VerifyIdentityModal.vue';
import SeAvatar from '@/src/shared/ui-kit/avatar/Avatar.vue';
import LoadingIcon from '@/src/components/LoadingIcon.vue';
import SeStartChatPopup from '@/src/modules/chat/components/popups/start-chat-popup.vue';
import SeConnectingAdvisorPopup from '@/src/modules/chat/components/popups/connecting-advisor-popup.vue';
import { useStartChatPopupsStore } from '@/src/store/startChatPopups.ts';
import SeTopUpBalancePopup from '@/src/modules/chat/components/popups/top-up-balance-popup.vue';
import SeNotEnoughMoneyPopup from '@/src/modules/chat/components/popups/not-enough-money-popup.vue';
import SeAddEditFeedbackPopup from '@/src/modules/feedback/components/add-edit-feedback-popup.vue';
import { useFeedbacksStore } from '@/src/store/feedbacks.ts';
import SeSendMessagePopup from '@/src/modules/inbox/components/send-message-popup.vue';
import SeMissedChatPopup from '@/src/modules/chat/components/popups/missed-chat-popup.vue';
import SeContinueWithSameAdvisorPopup from '@/src/modules/chat/components/popups/continue-with-same-advisor-popup.vue';
import useNotifications from '@/src/composables/useNotifications.ts';

const ACCEPT_TIMER_TIMEOUT = 50;
export default {
  name: 'App',
  components: {
    SeContinueWithSameAdvisorPopup,
    SeMissedChatPopup,
    SeSendMessagePopup,
    SeAddEditFeedbackPopup,
    SeNotEnoughMoneyPopup,
    SeTopUpBalancePopup,
    SeStartChatPopup,
    SeConnectingAdvisorPopup,
    SeAvatar,
    Layout,
    AlertModal,
    Avatar,
    BootstrapModal,
    StayOnlineModal,
    FontAwesomeIcon,
    TestCall,
    PhoneNumberConfirmation,
    VerifyIdentityModal,
    CookieWarning,
    FeedbacksModal,
    LoadingIcon,
  },
  data() {
    return {
      stopTimerLoading: false,
      // needToSubScribe: true,
      // needToSubScribePublic: true,
      // acceptTimerString: ACCEPT_TIMER_TIMEOUT.toString().toHHMMSS(true),
      acceptSeconds: ACCEPT_TIMER_TIMEOUT,
      loadingDecision: false,
      acceptChatLoading: false,
      rejectChatLoading: false,
      chatLeftTimer: null,
      acceptTimer: null,
      carryOnWithCurrentAdvisorChat: null,
      IS_OLD: !import.meta.env.SSR ? window.IS_OLD_BROWSER : false,
      isIE: false,
      // isNavigatorOnline: true,
    };
  },
  setup() {
    const { setNewNotification, submitReadNotification } = useNotifications();

    return {
      setNewNotification,
      submitReadNotification,
    };
  },
  mounted() {
    if (!import.meta.env.SSR && !window.isInitialized) {
      window.addEventListener(
        'click',
        function () {
          this.makeAnAction();
        }.bind(this)
      );

      window.addEventListener(
        'scroll',
        function () {
          this.makeAnAction();
        }.bind(this)
      );

      window.addEventListener(
        'keyup',
        function () {
          this.makeAnAction();
        }.bind(this)
      );

      let data = getClientData();
      if (data.browser == 'Microsoft Internet Explorer' && data.browser_version < 11) {
        this.isIE = true;
      }

      // this.getTimeDifference()

      window.isInitialized = true;
    }

    this.updateCurrentUserFromPrefetched();

    // Subscribing to push notifications.
    this.subscribeToEvents();
    this.setIsSubscribed(true);

    // Tracking page view.
    this.$api.post('/an', { url: window.location.href });

    // Reading cookie settings.
    this.readCookieWarning();

    // Enabling third-party scripts, if allowed.
    // if (this.cookiesAllowed) {
    this.initThirdPartyScripts(this.cookiesAllowed);
    // }
  },
  watch: {
    'currentUser.notifications'(newValue) {
      let notifications = _.chain(this.currentUser.notifications)
        .filter((item) => item?.mode == 'popup' && item?.is_unread)
        .value();
      if (notifications?.length) {
        this.showNotificationPopup(notifications[0]);
      }
    },
    waitingForChatConfirmation(newValue) {
      if (newValue && this.isAdvisor) {
        this.startAdvisorCallStartTimeoutTimer();
      }
    },
    'currentUser.active_chat'() {
      if (this.currentUser?.active_chat) {
        this.setLastChatRequestUser(this.currentUser.active_chat[this.oppositRole]);
      }

      // //make advisor action for stay online modal
      // this.makeAnAction();
      // if (this.currentUser.role_name === 'advisor' && this.firstLoad) {
      // 	this.firstLoad = false;
      // 	this.getOnlineAdvisors(Math.max(0, this.onlineAdvisorsCurrentPage - 1));
      // }
    },
  },
  methods: {
    ...mapActions(rootStore, [
      'setIsSubscribed',
      'readCookieWarning',
      // 'getTimeDifference',
    ]),
    ...mapActions(presenceStore, ['makeAnAction']),
    ...mapActions(useStartChatPopupsStore, ['setMissedChatPopupState']),
    ...mapActions(inboxStore, [
      'setNotEnoughMoneyInbox',
      // 'setNewReceivedInboxMessage',
    ]),
    ...mapActions(chatStore, [
      'updateChatInfoClientBalance',
      // 'setNewReceivedMessage',
      'saveAction',
      'saveParam',
      'setNewChatMessage',
      'setChatInfo',
      'setChatInfoStatus',
      'setChatInfoIsFree',
      'setChatInfoPauses',
      'setInitiatedChat',
      'resetChatMessages',
      'newChatMessage',
      'setChatInfoPaidMinutes',
      'scrollToMessage',
      'getActionByChatMode',
      'updateSavedAdvisor',
    ]),
    ...mapActions(useFeedbacksStore, ['setFeedbackFormChatId', 'setChatMode', 'setAdvisorInfo']),
    ...mapActions(usersStore, [
      'updateCurrentUserFromPrefetched',
      'getCurrentUser',
      'getInbox',
      'getSentFreeMinutes',
      'setCurrentUser',
      'getActiveChat',
      'setActiveChatPaidMinutes',
      'setIsTyping',
      'setMyProfileChanged',
      'advisorOnlineStatusChanged',
      'onlineStatusChanged',
      'setOnlineUsers',
      'setNewInboxMessage',
      'setInboxCharge',
      'setUserInboxChanges',
      'setChargeStatusChanged',
      'setReadInbox',
      'setInboxMessagesDeleted',
      'setInviteUsed',
      'setActiveChat',
      'setCurrentUserOnlineStatus',
      'setActiveChatTimer',
      'setActiveChatPauses',
      'setActiveChatStatus',
      'setActiveChatIsFree',
      'setAccountActivated',
      'setNewCurrentUserBlocks',
      'setDeleteCurrentUserBlock',
      'setCurrentUserAmount',
      'userJoined',
      'userLeft',
      'setFreeMinutesUsed',
      'getFreeMinutes',
      'cancelSubscription',
      'confirmSubscription',
    ]),
    // handleSubscribe() {
    // 	if (import.meta.env.SSR) {
    // 		return false;
    // 	}
    //
    // 	this.subscribeToEvents()
    // },
    submitCarryOnWithAdvisor() {
      this.saveAction(this.getActionByChatMode(this.carryOnWithCurrentAdvisorChat.mode));
      this.saveParam(this.carryOnWithCurrentAdvisorChat.advisor.id);
      this.carryOnWithCurrentAdvisorChat = null;
      this.setNotEnoughMoneyInbox(true);
    },
    showNotificationPopup(notification) {
      this.$refs.alert.show('', notification.body, '', () => {
        window.location.href = notification.url;
      });
      this.submitReadNotification(notification);
    },
    subscribeToEvents() {
      if (import.meta.env.SSR) {
        return false;
      }

      if (this.currentUser?.id) {
        // if (this.needToSubScribe && this.currentUser?.id) {

        // Using broadcasting for authenticated users only.

        // Subscribing private channels.
        this.$messaging.subscribe({
          NewInboxMessage: (e) => {
            // Adding the message to the inbox.
            this.setNewInboxMessage(e.message);

            // if (
            // 	_.chain(this.currentUser.inboxShort)
            // 		.filter(item => item.id === e.message.inbox_id)
            // 		.value()
            // 		.length
            // 	|| _.chain(this.currentUser.inbox)
            // 		.filter(item => item.id === e.message.inbox_id)
            // 		.value()
            // 		.length
            // ) {
            // 	this.setNewInboxMessage(e.message);
            // // } else {
            // 	// this.getInbox({page: 1});
            // }
            // else
            // this.scrollToMessage()
            // if (
            // 	e.message.sender_id === this.currentUser.id
            // ) {
            // 	this.setNewReceivedInboxMessage(e.message.id)
            // }

            // Playing notification sound if not currently in the inbox where the message received.
            if (
              e.message.sender_id &&
              this.currentUser?.id != e.message.sender_id &&
              (this.$route.path != '/dashboard/inbox' ||
                (e.message.inbox_id && this.currentInbox?.id != e.message.inbox_id))
            ) {
              this.$toast({
                message: 'You have received new inbox message.',
                url: '/dashboard/inbox?id=' + e.message.inbox_id,
                icon: 'ion-email',
              });
              this.$notificationSound.play();
            }
          },

          MissedInboxMessageEvent: (e) => {
            if (e.sender_id !== this.currentUser.id && e.receiver_id !== this.currentUser.id) {
              return false;
            }

            if (
              _.chain(this.currentUser.inboxShort)
                .filter((item) => item.id === e.message.inbox_id)
                .value().length !== 0 ||
              _.chain(this.currentUser.inbox)
                .filter((item) => item.id === e.message.inbox_id)
                .value().length !== 0
            ) {
              this.setNewInboxMessage(e);
            } else {
              this.getInbox({ page: 1 });
            }

            if (
              this.currentUser &&
              e.message.sender_id !== this.currentUser.id &&
              this.$route.path !== '/dashboard/inbox' &&
              (!this.currentInbox || (this.currentInbox && this.currentInbox.id !== e.message.inbox_id))
            ) {
              this.$toast({
                message: 'You have received new inbox message.',
                url: '/dashboard/inbox?id=' + e.message.inbox_id,
                icon: 'ion-email',
              });
              this.$notificationSound.play();
            }
          },

          NewMessageEvent: (e) => {
            if (!this.$route.path || this.$route.path !== '/dashboard/chat') {
              this.$toast({
                message: `You have received new message.`,
                url: '/dashboard/chat',
                icon: 'ion-chatbubble',
              });
            }

            if (
              this.chatInfo.id
              // && this.currentUser.id !== e.message.sender_id
              // || e.message.is_image
              // || e.message.is_hire
            ) {
              this.setNewChatMessage(e.message);
              // } else if (
              // 	this.currentUser.id === e.message.sender_id
              // ) {
              // 	this.setNewReceivedMessage(e.message.id);
            } else if (this.currentUser.id !== e.message.sender_id && this.$route.path !== '/dashboard/chat') {
              this.$messageSound.play();
            }
          },

          ChatMessageRetry: (e) => {
            this.newChatMessage({
              id: e.message.id,
              username: e.message.sender_id,
              text: e.message.text,
              created_at: e.message.created_at,
              is_image: !!e.message.is_image,
            });
          },

          NotificationEvent: (e) => {
            if (
              _.chain(this.currentUser.notifications)
                .filter((item) => item && item.id === e.notification.id)
                .value().length === 0
            ) {
              this.$notificationSound.play();
            }

            this.setNewNotification(e);

            // Making it possible to mark the notification read right after being shown.
            if (!e.notification.is_unread) {
              e.notification.is_unread = true;
            }

            if (e.notification?.mode == 'popup') {
              this.showNotificationPopup(e.notification);
            } else {
              this.$toast({
                message: e.notification.body,
                url: e.notification.url,
                icon: e.notification.icon,
              });
            }
          },
          NewInboxChargeEvent: (e) => {
            this.setInboxCharge(e);
          },
          UserInboxChanged: (e) => {
            this.setUserInboxChanges(e);
          },
          ChargeStatusChanged: (e) => {
            this.setChargeStatusChanged({
              inbox_id: e.inbox.id,
              charge: e.charge,
            });
            this.$forceUpdate();
          },
          InboxMessagesReadEvent: (e) => {
            this.setReadInbox({
              inbox_id: e.inbox.id,
              user_id: e.user_id,
            });
            this.$forceUpdate();
          },
          InboxMessagesDeletedEvent: (e) => {
            this.setInboxMessagesDeleted(e);
          },
          InvationUsedEvent: (e) => {
            this.setInviteUsed(e);
          },
          NewChatSessionEvent: (e) => {
            if (e.chat) {
              this.setInitiatedChat(null);
              this.setActiveChat(e.chat);
            } else {
              this.setInitiatedChat(null);
              this.getActiveChat()
                .then((res) => {})
                .catch((err) => {
                  this.$toast({
                    message: 'Error during getting active chat, contact support team please.',
                    type: 'error',
                  });
                });
            }
          },
          ChatSessionClosedEvent: async (e) => {
            const chat = e.chat ?? this.chatInfo ?? null;

            if (this.isUser) {
              if (!this.currentUser) {
                // userSometimes it might be empty for some reason, then reading it.
                await this.getCurrentUser();
              }
              const user = this.currentUser;
              let advisor = chat.advisor ?? this.savedAdvisor ?? null;
              if (!advisor) {
                await this.updateSavedAdvisor(chat.advisor_id);
                advisor = this.savedAdvisor;
              }

              this.resetChatMessages();
              this.setActiveChat(null);
              this.setChatInfo({});

              // console.warn('ChatSessionClosedEvent', e, this.isUser, e.payment.paid_minutes, chat?.is_free, user.id, advisor?.id, user.amount, Number(user.amount), Number(chat.per_minute_fee))

              if (
                e.payment.paid_minutes !== 0 &&
                // && !chat?.is_free
                advisor.id
              ) {
                if (Number(user.amount) < Number(chat.per_minute_fee)) {
                  this.carryOnWithCurrentAdvisorChat = {
                    id: chat.id,
                    mode: chat.mode,
                    advisor: {
                      id: advisor.id,
                      name: advisor.name,
                      avatar: advisor.avatar,
                    },
                  };
                  // this.$router.push('/');
                } else {
                  this.setFeedbackFormChatId(chat.id);
                  this.setChatMode(chat.mode);
                  this.setAdvisorInfo(chat.advisor);
                  console.log('ChatSessionClosedEvent', chat);
                }
              } else {
                // Redirecting an unhappy client (who decided not to pay for the continuing the chat)
                // to online advisors list.
                this.$router.push('/advisors/online');
              }
            } else if (this.isAdvisor) {
              // Redirecting to the chat history.
              this.$router.push({ path: '/dashboard/history/:id', params: { id: chat.id } });
            }
          },
          UserStatusChanged: (e) => {
            this.setCurrentUserOnlineStatus(e.status);
          },
          ChatSessionTimeoutReject: (e) => {
            if (this.isUser) {
              this.setMissedChatPopupState(true);
              if (this.$route.query.action) {
                this.$router.push(this.$route.path);
              }
            } else if (this.isAdvisor) {
              this.setMissedChatPopupState(true);
              // this.$toast({
              // 	message: 'You have missed chat session. Be more careful next time!',
              // 	icon: 'ion-ios-information'
              // });

              // Updating user status info (advisor becomes offline).
              this.getCurrentUser();
            }
            this.setActiveChat(null);
            this.setChatInfo({});
          },
          ChatStateChanged: (e) => {
            if (e.chat.status === 'active') {
              this.setActiveChat(e.chat);
              this.$router.push('/dashboard/chat');
              // window.location.reload();
            } else if (e.chat.status === 'canceled') {
              this.setActiveChat(null);
              if (this.isAdvisor) {
                this.$toast({
                  message: 'Client has canceled his chat request!',
                  icon: 'ion-ios-information',
                });
                if (this.$chatSound.playing()) {
                  this.$chatSound.stop();
                }
              } else if (this.isUser) {
                this.$toast({
                  message: 'Advisor has canceled his chat!',
                  icon: 'ion-ios-information',
                });
                if (this.$chatSound.playing()) {
                  this.$chatSound.stop();
                }
              }
            } else {
              this.setActiveChat(null);
              if (this.isUser) {
                this.$toast({
                  message: 'This chat request rejected by timeout!',
                  icon: 'ion-ios-information',
                });
              } else if (this.isAdvisor) {
                this.$toast({
                  message: 'This chat request rejected by timeout!',
                  icon: 'ion-ios-information',
                });
              }
              // This event is sent from inside a request, so need to let it finish and then
              // changing the page to prevent TypeError: NetworkError when attempting to fetch resource.
              this.$nextTick(() => {
                this.$router.push('/home');
              });
            }

            if (this.acceptTimer) {
              if (this.$chatSound.playing()) {
                this.$chatSound.stop();
              }
              this.acceptTimer.cancel();
            }
          },
          ChatPaidMinutesChanged: (e) => {
            this.setActiveChatPaidMinutes(e.minutes);
            this.setChatInfoPaidMinutes(e.minutes);
            if (this.isAdvisor) this.updateChatInfoClientBalance();
          },
          ChatSessionTimerStartedEvent: (e) => {
            this.setActiveChatTimer(e.chat.started_at);
          },
          ChatSessionPausedEvent: (e) => {
            this.setActiveChatStatus(e.chat.status);
            this.setChatInfoStatus(e.chat.status);
            this.setActiveChatPauses(e.pauses);
            this.setChatInfoPauses(e.pauses);
          },
          ChatSessionUnPausedEvent: (e) => {
            this.setActiveChatStatus(e.chat.status);
            this.setActiveChatIsFree(e.chat);
            this.setChatInfoStatus(e.chat.status);
            this.setChatInfoIsFree(e.chat);
            this.setActiveChatPauses(e.pauses);
            this.setChatInfoPauses(e.pauses);

            if (this.isAdvisor) {
              this.updateChatInfoClientBalance();
            }
          },
          AccountActivatedEvent: (e) => {
            this.setAccountActivated();
          },
          UserBlockedYouEvent: (e) => {
            this.setNewCurrentUserBlocks(e);
          },
          UserUnBlockedYouEvent: (e) => {
            this.setDeleteCurrentUserBlock(e);
          },
          UserBlockedEvent: (e) => {
            this.$refs.alert.show(
              '',
              e.block_reason ?? 'Your account was blocked for a suspicious behavior.',
              '',
              () => {
                this.$auth.logout();
                this.$router.push({ path: '/login' });
              }
            );
          },
          AmountChangedEvent: (e) => {
            this.setCurrentUserAmount(e);
          },
          FreeMinutesUsedEvent: (e) => {
            this.getSentFreeMinutes();
            this.setFreeMinutesUsed(e);
          },
          FreeMinutesReceivedEvent: (e) => {
            this.getSentFreeMinutes().then((res) => {
              this.$forceUpdate();
            });
          },

          TypingInboxEvent: (e) => {
            this.setIsTyping(e);
          },
          TypingChatEvent: (e) => {
            this.setIsTyping(e);
          },
        });

        // Reading from the broadcasting service which users are online.
        this.$messaging.subscribe({
          here: (users) => {
            this.setOnlineUsers(users);
          },
          joining: (user) => {
            this.userJoined(user);
          },
          leaving: (user) => {
            this.userLeft(user);
          },
        });
        this.needToSubScribe = false;

        // Subscribing public channels.
        // Using broadcasting for authenticated users only.
        this.$messaging.subscribe({
          ProfileChangedEvent: (e) => {
            if (e.id === this.currentUser.id) {
              this.setMyProfileChanged(e);
            }
          },

          HardRefreshPage: (e) => {
            window.location.reload(true);
          },

          UserPresenceStatusChanged: (e) => {
            if (this.currentUser.id === e.user) {
              this.currentUser.status = e.status;

              if (this.stayOnlineTimer) {
                this.stayOnlineTimer.cancel();
              }
              if (this.stayOnlineCountdownTimer) {
                this.stayOnlineCountdownTimer.cancel();
                this.stayOnlineCountdownTimer = null;
              }
            }
            if (this.userProfile && this.userProfile.id === e.user) {
              this.userProfile.status = e.status;
            }

            //adjust my advisors
            this.advisorOnlineStatusChanged(e);

            this.onlineStatusChanged({ id: e.user, status: e.status });
          },
        });

        this.$nextTick(() => {
          // Due to initialization should be made after all subscriptions are made, delaying it.
          this.$messaging.init(this.currentUser);
        });
      }
    },
    //update accept chat request timer
    startAdvisorCallStartTimeoutTimer() {
      if (this.acceptTimer) {
        this.acceptTimer.cancel();
      }
      if (
        !this.currentUser.id ||
        this.currentUser.active_chat === null ||
        this.currentUser.active_chat.status !== 'pending' ||
        this.isUser
      ) {
        return false;
      }
      this.acceptTimer = this.makeGlobalTimer();

      this.$chatSound.play();
    },
    //make chat accepting timer with actions
    makeGlobalTimer() {
      let _this = this;
      // register the global timer
      let id = setInterval(function () {
        if (!_this.currentUser || !_this.currentUser.active_chat || !_this.currentUser.active_chat.created_at) {
          _this.acceptTimer.cancel();
          return false;
        }
        let timeNow = TimeCalculations.rawAddTime(TimeCalculations.getCurrentTime(), -_this.timeDifference, 'seconds');
        let seconds = TimeCalculations.getSecondsFromDate(_this.currentUser.active_chat.created_at, timeNow);

        _this.acceptSeconds = ACCEPT_TIMER_TIMEOUT - seconds;
        if (_this.acceptSeconds <= 0) {
          _this.autoReject();
        }
        // _this.acceptTimerString = _this.acceptSeconds.toString().toHHMMSS(true)
      }, 1000);

      // return a Global Timer object
      return {
        id: function () {
          return id;
        },
        cancel: function () {
          if (id !== null) {
            clearInterval(id);
            id = null;
          }
        },
      };
    },
    //auto reject chat request (miss chat)
    autoReject() {
      if (import.meta.env.SSR) {
        return false;
      }

      if (
        !this.currentUser.id ||
        this.currentUser.active_chat === null ||
        this.currentUser.active_chat.status !== 'pending' ||
        this.isUser
      )
        return false;
      if (this.acceptTimer) this.acceptTimer.cancel();
      this.rejectChatLoading = true;
      this.$api
        .post('/api/chat/timeout', {})
        .then((res) => {
          this.rejectChatLoading = false;
          if (this.$chatSound.playing()) {
            this.$chatSound.stop();
          }
          this.setActiveChat(null);
          this.setChatInfo({});
        })
        .catch((error) => {
          console.error(error);
          this.rejectChatLoading = false;
          if (error.response && error.status === 404) {
            this.setActiveChat(null);
            this.setChatInfo({});
          }
        });
    },
    acceptChatRequest() {
      if (import.meta.env.SSR) {
        return false;
      }

      if (this.acceptTimer) this.acceptTimer.cancel();
      this.acceptChatLoading = true;
      this.$api
        .post('/api/chat/accept', {})
        .then((res) => {
          this.acceptChatLoading = false;
          if (this.$chatSound.playing()) {
            this.$chatSound.stop();
          }
          this.$router.push('/dashboard/chat');
        })
        .catch((error) => {
          console.error(error, error.response);
          this.acceptChatLoading = false;
          if (error.response && error.status === 404) {
            this.setActiveChat(null);
            this.setChatInfo({});
          }
        });
    },
    rejectChatRequest() {
      if (import.meta.env.SSR) {
        return false;
      }

      if (this.acceptTimer) this.acceptTimer.cancel();
      this.rejectChatLoading = true;
      this.$api
        .post('/api/chat/reject', {})
        .then((res) => {
          this.rejectChatLoading = false;
          if (this.$chatSound.playing()) {
            this.$chatSound.stop();
          }
          this.setActiveChat(null);
          this.setChatInfo({});
        })
        .catch((error) => {
          console.error(error, error.response);
          this.rejectChatLoading = false;
          if (error.response && error.status === 404) {
            this.setActiveChat(null);
            this.setChatInfo({});
          }
        });
    },
    initThirdPartyScripts(cookiesAllowed) {
      this.$analytics.init(cookiesAllowed);
    },
  },
  computed: {
    ...mapState(usersStore, [
      'currentUser',
      'presence',
      'onlineUsers',
      'isUser',
      'isAdvisor',
      'isAdmin',
      'subscribingToPlan',
      'subscribeLoading',
      'isActiveSubscriptionApproval',
    ]),
    ...mapState(chatStore, ['canceling', 'savedAdvisor', 'chatInfo', 'setLastChatRequestUser']),
    ...mapState(rootStore, ['initiatedChat', 'cookiesAllowed']),
    ...mapState(inboxStore, ['currentInbox']),
    oppositRole() {
      return this.isUser ? 'advisor' : 'client';
    },
    waitingForChatConfirmation() {
      return !!(this.currentUser.active_chat && this.currentUser.active_chat.status === 'pending');
    },
  },
};
</script>

<style lang="scss">
@import '../assets/styles/index.scss';

@import '@/src/styles/variables';

@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/mixins/buttons';
@import 'bootstrap/scss/mixins/border-radius';
@import 'bootstrap/scss/mixins/transition';
@import 'bootstrap/scss/mixins/gradients';
@import 'bootstrap/scss/mixins/box-shadow';
@import 'bootstrap/scss/mixins/caret';
@import 'bootstrap/scss/mixins/table-variants';
@import 'bootstrap/scss/mixins/forms';
@import 'bootstrap/scss/mixins/list-group';
@import 'bootstrap/scss/mixins/backdrop';
@import 'bootstrap/scss/mixins/alert';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/alert';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/utilities/api';
/* Full bootstrap package for tests. To find required parts see files in bootstrap module folder.
@import "bootstrap";
*/

@import '@/src/styles/animated_popups';
@import '@/src/styles/chat_page';
@import '@/src/styles/inbox_page';

@import '/static/assets/css/colors';
@import '/static/assets/css/components';
@import '/static/assets/css/components/index';
/*
@import "/static/assets/css/color-full";
@import "/static/assets/css/components-full";
*/
@import '/static/assets/css/bootstrap-extended';

@import '@fortawesome/fontawesome-svg-core/styles';
@import '@/src/styles/flipNotifications';

.old-browser-panel {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #f9f9f9;
  z-index: 1000000;
  top: 0;
  right: 0;
}

.browsers-wrapper {
  margin: auto;
  width: auto;
  margin-top: 10px;
}

.browsers-wrapper td {
  vertical-align: middle;
  padding: 5px 10px;
}

.browsers-wrapper img {
  width: 50px;
  height: 50px;
}

.old-browser-panel h2,
.old-browser-panel p {
  text-align: center;
  margin-top: 10px;
}

.is-fixed {
  width: 100vw;
  height: 100vh;
}

.spaced {
  margin: 20px 0;
}

@media screen and (min-width: $desktop) {
  .navbar-container .search-input {
    width: auto;
  }
}

.is-text-muted {
  opacity: 0.7;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*.app-content {*/
/*min-height: calc(100vh - 165px);*/
/*}*/
.App,
.view {
  height: 100%;
}

.blank-page {
  .App {
    height: auto;
  }

  .app-content.center-layout {
    .flexbox-container {
      min-height: 100vh;
      height: auto;
      /*padding: 20px 0;*/
    }
  }
}

.full-height-page {
  padding-top: 0 !important;

  #app {
    min-height: 100%;
  }

  .App {
    min-height: 100vh;
  }
}

@media screen and (max-width: $mobile) {
  .el-range-editor {
    width: 100% !important;
  }
}

.btn {
  &[disabled] {
    opacity: 0.7;
    pointer-events: none;
    border: none;
  }
  &.btn-paypal,
  &.btn-stripe {
    @apply bg-[length:auto_50%] bg-center;
    padding: 0;
    height: 48px;
    border-radius: 94px;
    width: 100%;
    background-image: url('/static/images/paypal.svg');
    background-repeat: no-repeat;
    background-color: #019cde;

    &:disabled {
      opacity: 0.5;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &.btn-stripe {
    background-size: 50%;
    background-color: var(--se-color-primary-100);
    background-image: url('/static/images/Stripe.png');
  }

  &.btn-stripe-other {
    background-size: 50%;
    background-color: var(--se-color-neutral-80);
    background-image: url('/static/images/StripeOther.png');
  }

  &.btn-paynl {
    @apply bg-cover bg-center;
    padding: 0;
    height: 48px;
    border-radius: 94px;
    width: 100%;
    color: white;
    font-size: 16px;
    background: var(--se-color-primary-100);

    &:hover {
      opacity: 0.8;
    }
  }
}

.chat-content p {
  word-break: break-word;
}

.jumping-dots span {
  position: relative;
  bottom: 0px;
  -webkit-animation: jump 1500ms infinite;
  animation: jump 2s infinite;
}

.jumping-dots .dot-1 {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

.jumping-dots .dot-2 {
  -webkit-animation-delay: 400ms;
  animation-delay: 400ms;
}

.jumping-dots .dot-3 {
  -webkit-animation-delay: 600ms;
  animation-delay: 600ms;
}

@-webkit-keyframes jump {
  0% {
    bottom: 0px;
  }

  20% {
    bottom: 5px;
  }

  40% {
    bottom: 0px;
  }
}

@keyframes jump {
  0% {
    bottom: 0px;
  }

  20% {
    bottom: 5px;
  }

  40% {
    bottom: 0px;
  }
}

// chat app fixes
@media screen and (max-width: $tablet) {
  .horizontal-layout.chat-application .app-content {
    /*padding-top: 55px!important;*/
    height: calc(100% - 30px) !important;
    min-height: calc(100% - 30px) !important;
  }
}

//popup
.popup {
  &.is-active {
    z-index: 10000;
  }

  .popup-close {
    background-color: transparent;
    border: none;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

//modal
.modal {
  z-index: 100004 !important;
}

.modal-backdrop {
  z-index: 100003 !important;
}

//dropdown
.custom-dropdown-menu {
  display: inline-block;
  position: relative;

  .button {
    i {
      margin-right: calc(-0.375em - 1px) !important;
      margin-left: 5px !important;
    }

    &.is-small {
      font-size: 0.75rem !important;

      &:hover {
        font-weight: 300;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .menu {
    -webkit-animation: slide-down 0.2s ease 1;
    animation: slide-down 0.2s ease 1;
    display: none;
    right: 0;
    position: absolute;
    top: 100%;
    z-index: 10;
    background-color: #fff;
    font-size: 0.9rem;
    white-space: nowrap;
    border: 1px solid $gray;
    min-width: 120px;

    &.is-left {
      right: auto;
      left: 0;
    }

    &.is-top {
      top: calc(-100% - 27px);
    }

    &.is-open-top {
      top: auto;
      bottom: 24px;
    }

    li {
      padding: 5px 10px;
      border-bottom: 1px solid $gray;
      color: $dark-text;
      cursor: pointer;

      i {
        margin-right: 5px;
      }

      a {
        display: block;
        color: $dark-text;

        &:hover {
          color: $dark-text;
        }
      }

      &:hover {
        color: $dark-text-hover;
      }

      &:last-child {
        border-bottom: 0px;
      }

      &[disabled] {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  &.dropdown-right .menu {
    left: auto;
    right: 0;
  }

  &.active .menu,
  .dropdown-toggle:focus + .menu,
  .menu:hover {
    display: block;
  }

  .btn-group .dropdown-toggle:nth-last-child(2) {
    border-bottom-right-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }
}

body {
  font-size: 16px;

  &.with-popup {
    height: 100vh;
    overflow: hidden;
  }
}

.is-justify-space-between {
  justify-content: space-between;
}

/* Replacing element-plus select with built in one */
select.cust-select {
  @apply rounded-[8px] border;
  border: solid 1px var(--se-color-neutral-40);
  border-radius: 4px;
  padding: 3px 5px;
  margin: 1px 11px;
  width: calc(100% - 22px);
  /*-moz-appearance: none;
  -webkit-appearance: none;*/
  appearance: none;
  background: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTAyNCAxMDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGw9InJnYigxOTIsIDE5NiwgMjA0KSIgZD0ibSA0ODguODMyLDc0NC43NzI4MyAtMzM5Ljg0LC0zNTYuNjcyIGEgMzIsLTMyIDAgMCAxIDAsLTQ0LjE2IGwgMC4zODQsLTAuMzg0IGEgMjkuNDQsLTI5LjQ0IDAgMCAxIDQyLjY4OCwwIGwgMzIwLDMzNS44NzIgMzE5Ljg3MiwtMzM1Ljg3MiBhIDI5LjQ0LC0yOS40NCAwIDAgMSA0Mi42ODgsMCBsIDAuMzg0LDAuMzg0IGEgMzIsLTMyIDAgMCAxIDAsNDQuMTYgbCAtMzM5Ljg0LDM1Ni42NzIgYSAzMiwtMzIgMCAwIDEgLTQ2LjMzNiwwIHoiIGRhdGEtdi1mZDAzZTIxYj0iIj48L3BhdGg+PC9zdmc+');
  background-repeat: no-repeat;
  background-position: right 0.45em top 50%;
  background-size: 1em auto;
}

select.cust-select:focus,
select.cust-select:focus:hover {
  border-color: rgba(59, 130, 246, 0.5);
}

select.cust-select:hover {
  border-color: rgb(192, 196, 204);
}

select.cust-select:focus option[value=''] {
  display: none;
}

select.cust-select option {
  color: #333333;
}

select.cust-select option.is-discount {
  color: #000000;
}

/* Fixing element-plus select popup in modal dialogs. */
.el-select__popper {
  z-index: 120000 !important;
  margin-left: -10px !important;
}

.el-select-dropdown__item {
  padding: 0 10px !important;
}

.el-select-dropdown {
  z-index: 100005 !important;
}

@keyframes loading {
  0% {
    opacity: 0.25;
    filter: grayscale(1);
  }

  50% {
    opacity: 0.75;
    filter: grayscale(0.5);
  }

  100% {
    opacity: 0.25;
    filter: grayscale(1);
  }
}

.is-loading {
  opacity: 0.5;
  pointer-events: none;
  filter: grayscale(1);
  animation-name: loading;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.media-list {
  display: flex;
  flex-wrap: wrap;
}

.media-list .media {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.media-list .media .media-body {
  flex: 1 1 0%;
}

.media-list .media .media-body button,
.media-list .media .media-body a {
  margin-right: 1rem;
}

.card-header {
  background: transparent !important;
  padding-top: 1.5em;
}

.btn-group + .btn-group,
.dropdown-menu + button,
button + .dropdown-menu,
button + button,
.btn + button,
button + .btn {
  margin-left: 0.75em;
}

fieldset.form-group {
  margin-bottom: 1rem;
}

.breadcrumb-item a,
.breadcrumb-item a:visited,
.breadcrumb-item a:link,
.breadcrumb-item a:hover {
  text-decoration: none;
  color: #000000;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.card.filters .form-group label.checkbox,
.card.filters .form-group label.radio {
  margin-right: 1em;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: $spacer * 0.25;
}

.mr-2 {
  margin-right: $spacer * 0.5;
}

.mr-3 {
  margin-right: $spacer;
}

.mr-4 {
  margin-right: $spacer * 1.5;
}

.mr-5 {
  margin-right: $spacer * 3;
}

.font-weight-bold {
  font-weight: bold;
}

.btn-green {
  background-color: $green !important;
  border-color: $green !important;
  color: #ffffff;
}

.btn-red {
  background-color: $red !important;
  border-color: $red !important;
  color: #ffffff;
}

.status {
  text-align: center;
  text-transform: capitalize;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 0.5rem;

  .status-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  &.is-online {
    color: $green;

    .status-dot {
      background-color: $green;
    }
  }

  &.is-busy {
    color: $red;

    .status-dot {
      background-color: $red;
    }
  }

  &.is-offline {
    color: $offline-gray;

    &.is-text {
      color: $orange;
      font-weight: bold;
      text-transform: none;
    }

    .status-dot {
      background-color: $offline-gray;
    }
  }
}
</style>
