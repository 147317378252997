<template>
  <div class="star-rating-wrapper" :class="{ 'is-hovered': hoveredIconIndex, 'big': bigMode }">
    <label v-for="(rating, index) in ratingsArray" :style="getLabelStyle(rating)" @mouseover="canChange && onHover(rating)"
           @mouseout="canChange && onHoverOut()" @click="canChange && starClicked(index+1)">
      <input type="checkbox" :name="index" :value="value">
      <!--{{ icon }}-->
      <se-svg name="star-filled" class="text" :height="30" :width="30" />
    </label>
  </div>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import SeSvg from "@/src/shared/ui-kit/svg/Svg.vue";

const props = withDefaults(defineProps<{
  modelValue: string | number,
  canChange?: boolean,
  bigMode?: boolean,
  iconsCount?: number,
  customColor?: string,
}>(), {
  canChange: false,
  bigMode: false,
  iconsCount: 5,
  customColor: '#FACC15',
});

const emit = defineEmits<{(e: 'update:modelValue', value: string | number): any}>();

const value = computed<string | number>({
  get() {
    return props.modelValue;
  },
  set(val: string | number) {
    emit('update:modelValue', val);
  },
});

const hoveredIconIndex = ref(0);
const color = ref('#FACC15');

onMounted(() => {
  // Validate custom color
  if (!validateColor(props.customColor))
    throw new Error('[Rating Component]: Custom color is not valid!');
  else
    color.value = props.customColor;
});

const onHover = (index) => {
  hoveredIconIndex.value = index;
}

const onHoverOut = () => {
  hoveredIconIndex.value = 0;
}

const starClicked = (index) => {
  // canChange && value=index
  value.value = index
}

const getLabelStyle = (rating) => {
  const styles = {};
  // If icon hovered
  if (hoveredIconIndex.value && hoveredIconIndex.value >= rating) {
    styles.color = color.value;
    styles.opacity = 0.5;
  }
  // If icon is selected but not hovered
  else if (value.value && value.value >= rating && !hoveredIconIndex.value)
    styles.color = color.value;

  return styles;
}

const validateColor = (color) => {
  if (color === "" || color === "inherit" || color === "transparent")
    return false;
  const image = document.createElement("img");
  image.style.color = "rgb(0, 0, 0)";
  image.style.color = color;
  if (image.style.color !== "rgb(0, 0, 0)")
    return true;
  image.style.color = "rgb(255, 255, 255)";
  image.style.color = color;
  return image.style.color !== "rgb(255, 255, 255)";
}

const ratingsArray = computed(() => {
  return Array(props.iconsCount).fill().map((e, i) => i + 1);
});

</script>

<script lang="ts">

export default {
  name: 'SeRatingStars',
};
</script>

<style lang="scss" scoped>
@import "@/src/styles/variables";

.star-rating-wrapper {
  display: inline-block;

  label {
    color: $gray;
    /*padding: 3px;*/
    line-height: 1;
    cursor: pointer;
    font-size: 1.3rem;
    display: inline-block;
    margin-bottom: 0;

    input {
      display: none;
    }

    &[disabled] {
      pointer-events: none;
    }
  }

  &.is-hovered {
    label {
      color: $gray;
    }
  }

  &.big {
    label {
      font-size: 2rem;
    }
  }
}
</style>