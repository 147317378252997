import {HomeConfig} from "../../_core/types/theme/Home.type";

const home: HomeConfig =  {
    header: {
        title: 'Best Online Coaches',
        subtitle: 'Consult world\'s best business coaches online.',
        ctaText: 'Find your Coach Now',
        description: '*99.5% success from @ client reviews.',
        heroImage: '/static/images/promo/valentinesDay/timesy/home-bg.png',
    },
    topAdvisors: {
        title: 'Top Coaches Today On Site',
        descriptionOne: 'Timesy\'s top online business coaches have successfully completed over',
        descriptionTwo: 'consultations and are a go-to source for success.',
        ctaText: 'Show All Top Coaches'
    },
    categories: {
        title: 'Top Categories'
    },
    newAdvisors: {
        title: 'Gaining Popularity',
        description: 'Timesy\'s top online business coaches have successfully completed over 1M+ consultations and are a go-to source for success.',
        ctaText: 'See All Coaches'
    },
    promo: {
        title: '3 FREE minutes + 50% OFF',
        description: 'with each of your first 3 coaches.',
        ctaText: 'Try Now'
    },
    reasons: {
        title: 'Why Choose Us',
        reasons: [
            {
                colorScheme: 'feature',
                icon: 'eye',
                title: '1,128,366+',
                description: 'Total Consultations completed by Coaches',
            },
            {
                colorScheme: 'success',
                icon: 'dollar-sign',
                title: '100% Money Back',
                description: 'You’ll get a full refund, if you\'re not happy with your first consultation.',
            },
            {
                colorScheme: 'warning',
                icon: 'star',
                title: '542,834+',
                description: 'Total reviews left by customers on Timesy.',
            },
        ]
    },
    testimonials: {
        title: 'People Love Our Coaches',
        mainTestimonial: 'As always the real deal, with no sugarcoating and dead on. I highly recommend chatting with her, you will not be disappointed.',
        testimonials: [
            {
                name: 'Iva',
                text: 'What a wonderful experience. Completely on point and I look coward to how he described what is on the horizon.',
                date: '22 Jan 2024',
            },
            {
                name: 'Michelle',
                text: 'What a wonderful experience. Completely on point and I look coward to how he described what is on the horizon.',
                date: '22 Jan 2024',
            },
            {
                name: 'Alex',
                text: 'A very kind and compassionate reader. She provides a lot of clarity and insights to situation and share with you her advices. It is really nice chatting with her:)',
                date: '22 Jan 2024',
            },
        ]
    },
    howToStart: {
        title: 'How do I start?',
        steps: [
            {
                title: 'Create account',
                description: 'Sign up with Google, Facebook, or your email',
            },
            {
                title: 'Choose duration',
                description: 'Select a number of minutes you want to chat with coach.',
            },
            {
                title: 'Choose payment method',
                description: 'Pay with your credit card or via PayPal.',
            },
            {
                title: 'Start chatting!',
                description: 'Start talking to the coach and see your success!',
            },
        ],
    },
    about: {
        title: 'About Timesy',
        content: [
            'Explore our highest-rated coaches and medium services to navigate towards happiness and fulfillment in your life.',
            'From career guidance, financial predictions, pet communication, or getting a glimpse into your future, Timesy’s coaches are here to help you.',
            'Want to know more about death and crossover, or dive deep into your love life? Timesy’s secure online coach chats will align your life with the best insights and guidance!',
        ],
        ctaText: 'Learn more',
        image: '/static/images/home/about.png',
    },
    seo: {
        title: 'Online Business Coach Consultation at Timesy',
        description: 'Discover happiness and fulfillment with Timesy\'s top online coaches. Gain insights and life guidance from the most accurate business coaches at Timesy.com!',
    }
};

// @ts-ignore
export default home;
