<template>
  <header class="sticky top-0 left-0 w-full bg-white z-[101]">
    <template v-if="isPromoActive">
      <se-header-black-friday-banner />
    </template>
    <template v-else>
      <se-header-promo-banner />
    </template>
    <div class="px-16 md:px-32 py-12 md:py-16 border-b border-neutral-20 flex relative justify-between items-center l-header">
      <div class="flex-grow flex justify-between items-center ">
        <div class="flex items-center">
          <div v-if="!$route.path.includes('/get-started')" class="mr-16 md:mr-24 lg:hidden" @click="isMenuOpen = true">
            <se-icon name="menu" :size="36" class="hidden md:visible md:flex"/>
            <se-icon name="menu" :size="24" class="flex md:hidden"/>
          </div>
          <Link href="/">
            <img alt="Logo" :src="config.logoDark" class="h-32 md:h-48 inline-block"/>
          </Link>
        </div>
        <div class="flex items-center" v-if="!$route.path.includes('/get-started')">
          <nav class="l-header__nav" :class="{ 'is-mobile': isMobile, 'is-open': isMenuOpen }">
            <div class="p-16 lg:hidden border-b border-neutral-20 w-full mb-8">
              <div class="cursor-pointer" @click="isMenuOpen = false">
                <se-icon name="x" :size="24"/>
              </div>
            </div>
            <div class="lg:hidden px-16 py-12">
              <img alt="Logo" :src="config.logoDark" class="h-40"/>
            </div>
            <Link href="/advisors/online" class="font-semibold first-letter:uppercase">
              <span>
                {{ config.advisors }} Online
              </span>
            </Link>
            <se-header-categories return-classes="xl:hidden" class="font-semibold">
              <span>
                Categories
              </span>
            </se-header-categories>
            <Link href="/how-it-works" class="font-semibold">
              <span>
                How It Works?
              </span>
            </Link>
            <div/>
            <Link href="/about" class="font-semibold">
              <span>
                About Us
              </span>
            </Link>
          </nav>
          <div class="border-r border-neutral-30 h-40 ml-8 !mr-4 hidden xl:block xl:visible"/>
          <se-header-search/>
        </div>
      </div>
      <div v-if="!$route.path.includes('/get-started')">
        <div class="flex items-center pl-32 hidden md:visible md:inline-block">
          <Link href="/register">
            <se-button type="primary" size="large">
              Sign Up
            </se-button>
          </Link>
          <Link href="/login">
            <se-button type="tertiary" size="large" class="ml-8">
              Sign In
            </se-button>
          </Link>
        </div>
        <div class="pl-24 md:hidden">
          <Link href="/login" class="text-black text-h-m no-underline font-semibold">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import {ref, onMounted, watch, computed} from 'vue';
import {theme} from '@/theme';
import {getWindow} from '@/src/shared/helpers/getWindow';
import {usePromoBanner} from "@/src/shared/helpers/promoBanner";
import SeButton from '@/src/shared/ui-kit/button/Button';
import Link from '@/src/components/Link';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import SeHeaderCategories from './HeaderCategories';
import SeHeaderPromoBanner from './HeaderPromoBanner';
import SeHeaderSearch from './HeaderSearch';
import SeHeaderBlackFridayBanner from "@/src/shared/layout/header/HeaderBlackFridayBanner.vue";

const {windowWidth} = getWindow();
const isMenuOpen = ref<boolean>(false);
const isMobile = ref(true);

onMounted(() => {
  isMobile.value = windowWidth.value < 1200;
});

const {isPromoActive} = usePromoBanner()

watch(windowWidth, () => {
  isMobile.value = windowWidth.value < 1200;
});

const config = theme.general;
</script>

<script lang="ts">
export default {
  name: 'SeHeaderPublic',
};
</script>
