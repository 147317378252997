<template>
  <div class="w-full" v-bind="$attrs">
    <Link href="/dashboard/payments" class="flex items-center" data-cy="payments">
      <se-icon name="dollar-sign" :size="20" class="mr-16" />
      <span>Payments <span v-show="currentUser && (currentUser.amount || Number(currentUser.amount) === 0)">(${{
        Number(currentUser.amount).toFixed(2) }})</span></span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/dashboard/inbox" class="flex items-center" data-cy="inbox">
      <se-icon name="mail" :size="20" class="mr-16" />
      <span>Inbox <span v-show="getUnreadInbox()">({{ getUnreadInbox() }})</span></span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/dashboard/history" class="flex items-center" data-cy="chats">
      <se-icon name="message-circle" :size="20" class="mr-16" />
      <span>Chats</span>
    </Link>
  </div>
  <div class="w-full" v-bind="$attrs">
    <Link href="/dashboard/my-advisors" class="flex items-center" data-cy="advisors">
      <se-icon name="user-round" :size="20" class="mr-16" />
      <span>My advisors</span>
    </Link>
  </div>
  <div v-if="subscriptionEnabled" class="w-full" v-bind="$attrs">
    <Link href="/dashboard/subscriptions" class="flex items-center" data-cy="subscriptions">
      <se-icon name="user-check" :size="20" class="mr-16" />
      <span>Subscriptions</span>
    </Link>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import Link from '@/src/components/Link';
import SeIcon from '@/src/shared/ui-kit/icon/Icon';
import { usersStore } from '@/src/store/users';
import {theme} from "@/theme";

const {subscriptionEnabled} = theme.general;
const user = usersStore();
const { getUnreadInbox } = user;
const { currentUser } = storeToRefs(user);
</script>

<script lang="ts">
export default {
  name: 'SeHeaderMenuUser',
};
</script>
