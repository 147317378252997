<template>
  <se-popup v-if="visible" :classes="'sm:w-[448px]'" @close="setMissedChatPopupState(false)">
    <template v-if="isUser">
      <div class="flex flex-col items-center justify-center gap-16 w-full">
        <se-avatar-chat-option
          :id="savedAdvisor.id"
          :size="68"
          :name="savedAdvisor.name"
          :src="savedAdvisor.avatar"
          :chat-action="actionType ? actionType : 'text'"
          is-declined
        />
        <p class="text-h-l font-bold text-center max-w-[90%]">We're sorry, the advisor couldn't answer at this time.</p>
        <p class="flex flex-col items-center text-b-xs">
          You have remaining balance of ${{ Number(currentUser.amount).toFixed(2) }} to use with any advisor.
        </p>
      </div>
    </template>
    <template v-else>
      <p class="text-h-l font-bold text-center max-w-[90%]">You have missed a chat request</p>
      <p class="flex flex-col items-center text-b-xs">We have made your status <b>OFFLINE</b></p>
    </template>
    
    <template #footer>
      <div class="flex flex-col gap-16 mt-16">
        <se-button v-if="isUser" size="medium" class="w-full" @click="seeOtherAdvisors">
          Try another advisor
        </se-button>
        <se-button size="medium" class="w-full !ml-0" @click="activateSendMessageModal" type="tertiary">
          Send Message
        </se-button>
      </div>
    </template>
  </se-popup>
</template>

<script setup lang="ts">
import {storeToRefs} from "pinia";
import {computed, ref, watch} from "vue";
import {chatStore} from "@/src/store/chat";
import {usersStore} from "@/src/store/users";
import SePopup from "@/src/shared/ui-kit/popup/Popup.vue";
import SeAvatarChatOption from "@/src/shared/ui-kit/avatar-chat-option/AvatarChatOption.vue";
import SeButton from "@/src/shared/ui-kit/button/Button.vue";
import {getGlobalProperties} from "@/src/shared/helpers/getGlobalProperties";
import {inboxStore} from "@/src/store/inbox";
import {useStartChatPopupsStore} from "@/src/store/startChatPopups";

const {$router, $route, $api} = getGlobalProperties()

const popupStore = useStartChatPopupsStore()
const {isActiveMissedChatPopup, chatApprovalActionType} = storeToRefs(popupStore)
const {setMissedChatPopupState} = popupStore

const inbox = inboxStore()
const {openMessageModal} = inbox

const userStore = usersStore()
const {currentUser, isUser, isAdvisor} = storeToRefs(userStore)

const chat = chatStore()
const {savedAdvisor, lastChatRequestUser} = storeToRefs(chat)


const visible = computed(() => (isAdvisor.value || isUser.value) && isActiveMissedChatPopup.value)

const actionType = ref('');
watch(() => chatApprovalActionType.value, () => {
  // chatApprovalActionType gets deleted after start chat so we need to save the value
  if (chatApprovalActionType.value) {
    actionType.value = chatApprovalActionType.value
  }
})

const seeOtherAdvisors = () => {
  setMissedChatPopupState(false);
  if (!$route.path.includes('/advisors'))
    $router.push('/advisors/online');
}

const activateSendMessageModal = () => {
  setMissedChatPopupState(false);
  $api.get(`/api/user/${lastChatRequestUser.value?.id}`)
    .then((res: any) => openMessageModal(res.data))
}

// const counter = ref(15)
// watch(() => isActiveMissedChatPopup.value, (value) => {
//   if (value && isUser.value) {
//     const counterInterval = setInterval(() => {
//       counter.value -= 1;
//       if (counter.value <= 0) {
//         clearInterval(counterInterval)
//         setMissedChatPopupState(false)
//       }
//     }, 1000)
//   }
// })
</script>

<script lang="ts">
export default {
  name: 'SeMissedChatPopup',
};
</script>