import {useNotificationsStore} from '@/src/store/notifications';
import {Notification, NotificationsResponse} from '@/src/types/notifications';
import {getGlobalProperties} from '@/src/shared/helpers/getGlobalProperties';
import {usersStore} from "@/src/store/users";
import {storeToRefs} from "pinia";
import {computed} from "vue";

export default function useNotifications() {
	const {$api, $toast} = getGlobalProperties()

	const store = useNotificationsStore();
	const {notifications} = storeToRefs(store);
	const {readNotification} = store;

	const user = usersStore();
	const {getCurrentUser} = user;
	const {currentUser} = storeToRefs(user);

	const totalUnreadNotifications = computed(() => currentUser.value?.total_unread_notifications)

	if (!$api) {
		console.error("API instance is not available");
	}

	const loadNotifications = async (
		nextPageUrl: string,
		currentPage: number
	): Promise<NotificationsResponse> => {
		try {
			if (!$api) throw new Error('API instance not available');

			const response = await $api.get(`${nextPageUrl}?page=${currentPage}`);
			const notificationsResponse: NotificationsResponse = response.data;
			store.addNotifications(notificationsResponse.data);
			return notificationsResponse;
		} catch (error) {
			console.error('Failed to fetch notifications', error);

			if ($toast) {
				$toast({
					message: `Failed to fetch notifications`,
					type: 'error',
				});
			}
			throw error;
		}
	};

	const readAllNotifications = async () => {
		const notificationsPromise = []
		notifications.value.forEach((item: Notification) => {
			notificationsPromise.push(submitReadNotification(item))
		})
		Promise.all(notificationsPromise).then(getCurrentUser)
	}

	const submitReadNotification = (notification) => {
		if (!notification.is_unread)
			return false;

		return $api.post('/api/notifications/' + notification.id + '/read', {})
			.then(() => {
				readNotification(notification.id);
				currentUser.total_unread_notifications -= 1;
			})
	}

	const setNewNotification = (payload) => {
		if (notifications.value.filter(item => item && item.id === payload.notification.id).length > 0) {
			return false
		}
		if (notifications.value) {
			notifications.value?.unshift(payload.notification)
		} else {
			notifications.value = [payload.notification]
		}
	}

	return {
		loadNotifications,
		readAllNotifications,
		setNewNotification,
		submitReadNotification,
		totalUnreadNotifications
	};
}
