import {getGlobalProperties} from "@/src/shared/helpers/getGlobalProperties";
import {usersStore} from "@/src/store/users";
import {inboxStore} from "@/src/store/inbox";
import {storeToRefs} from "pinia";

export default function useInbox() {
    const { $api, $auth, $toast } = getGlobalProperties()

    const inbox = inboxStore()
    const {recepient} = storeToRefs(inbox)
    const {setSendingMessage, checkMessage, closeMessageModal} = inbox

    const users = usersStore()
    const {usersInApp} = storeToRefs(users)

    if (!$api) {
        console.error("API instance is not available");
    }

    const sendMessage = (message) => {
        if (!$auth.check()) {
            return false;
        }
        if (message === '' || message.trim() === '') {
            return false;
        }

        let request_data = {
            message: message,
            recepient_id: recepient.value,
        };

        request_data.status = usersInApp.value.indexOf(recepient.value) === -1 ? 'offline' : 'online';
        setSendingMessage(true);
        return $api.post('/api/user/inbox/message', request_data)
            .then(res => {
                setSendingMessage(false);
                checkMessage(res.data);
                $toast({
                    message: 'Your inbox message has been sent.',
                    type: 'success'
                });
                closeMessageModal()

                return res.data;
            })
            .catch(error => {
                setSendingMessage(false);
                closeMessageModal()
                // this.setMessageFailed(temp_id);
                console.error(error, error.response);
                if (error.response) {
                    $toast({
                        message: '<b>Error: </b>' + (error.response.message || error.response),
                        type: 'error'
                    });
                } else if (error.response?.data) {
                    $toast({
                        message: '<b>Error: </b>' + error.response.data,
                        type: 'error'
                    });
                }
                return false;
            })
    }

    return {
        sendMessage
    };
}
