<template>
  <se-popup v-if="visible"
            @close="toggleChatApprovalPopup(false, actionType)"
            :classes="'sm:w-[420px]'"
            :show-close="!!savedAdvisor"
  >
    <div v-if="savedAdvisor">
      <div class="flex flex-column items-center justify-center w-full">
        <se-avatar-chat-option :id="savedAdvisor.id" :size="68" :name="savedAdvisor.name" :src="savedAdvisor.avatar"/>
        <p class="text-h-xl pt-16 text-center font-bold">{{ savedAdvisor.name }}</p>
      </div>
      
      <div v-if="savedAdvisor.free_minutes_left || savedAdvisor.discount" class="pt-20 md:pt-24">
        <div
          class="flex items-center justify-center gap-8 rounded-12 p-8 px-16 text-h-s md:text-b-m text-center"
          :class="chatTypeContent[actionType]?.offer">
          <se-icon name="gift" :size="16"/>
          <div>
            <span v-if="savedAdvisor.free_minutes_left">{{ savedAdvisor.free_minutes_left }} Free min</span>
            <span v-if="savedAdvisor.free_minutes_left && savedAdvisor.discount">&nbsp;+&nbsp;</span>
            <span v-if="savedAdvisor.discount">{{ savedAdvisor.discount }}% OFF</span>
          </div>
        </div>
      </div>
      
      <div class="relative bg-neutral-10 rounded-12 flex justify-center mt-24 mb-8 pt-[11px] pb-[15px]">
        <div class="flex flex-col justify-center items-center w-1/2">
          <p class="text-h-xs font-bold mb-1">Per minute rate</p>
          <p>
          <span v-if="savedAdvisor.discount"
                class="line-through text-neutral-50 text-b-m mr-1">${{ formatPrice(chatPrice) }}</span>
            <span class="text-h-m font-bold" :class="chatTypeContent[actionType].text">
            ${{ savedAdvisor.discount ? formatPrice(discountedPrice) : formatPrice(chatPrice) }}/min
          </span>
          </p>
        </div>
        <div class="divider absolute bg-neutral-30 top-0 w-[0.5px] h-full"/>
        <div class="flex flex-col justify-center items-center w-1/2">
          <p class="text-h-xs font-bold mb-1">Your chat duration</p>
          <p>
            <span class="text-h-m font-bold" :class="chatTypeContent[actionType].text">{{ canChatDuration }} min</span>
          </p>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-3 items-center py-64" v-else>
      <se-spinner size="60px" class="mb-16" />
      <h1 class="text-h-l font-bold">
        Waiting for chat initiation...
      </h1>
      <p class="text-b-s text-center">
        We're initiating your chat. Please wait a moment, this will be completed in a few seconds.
      </p>
    </div>
    <template v-if="savedAdvisor" #footer>
      <se-button
          size="large"
          class="w-full"
          :class="chatTypeContent[actionType].bg"
          @click="chat.startChat(savedAdvisor.id, actionType)"
          :loading="initiatingChat === true"
      >
        Start
        {{ chatTypeContent[actionType]?.submitText }}
      </se-button>
      <template v-if="chatTypeContent[actionType].check">
        <div class="divider bg-neutral-30 top-0 w-full h-[1px] my-24"/>
        <div class="mb-16">
          <p class="text-b-s mb-16">{{ chatTypeContent[actionType].check.text }}</p>
          <se-button
              type="tertiary"
              size="medium"
              class="w-full"
              @click="emits('test-' + actionType)"
          >
            {{ chatTypeContent[actionType].check.buttonText }}
          </se-button>
        </div>
      </template>
    </template>
  </se-popup>
</template>

<script setup lang="ts">
import {storeToRefs} from "pinia";
import {computed, defineEmits, ref, watch} from 'vue';
import {chatStore} from "@/src/store/chat";
import {usersStore} from "@/src/store/users";
import {AdvisorService} from "@/src/modules/advisor/services/advisor.service";
import SeAvatarChatOption from "@/src/shared/ui-kit/avatar-chat-option/AvatarChatOption.vue";
import SeButton from "@/src/shared/ui-kit/button/Button.vue";
import SePopup from "@/src/shared/ui-kit/popup/Popup.vue";
import SeIcon from "@/src/shared/ui-kit/icon/Icon.vue";
import SeSpinner from "@/src/shared/ui-kit/spinner/Spinner.vue";
import SeLoading from "@/src/shared/ui-kit/loading/Loading.vue";
import LoadingIcon from "@/src/components/LoadingIcon.vue";
import {useStartChatPopupsStore} from "@/src/store/startChatPopups";

const emits = defineEmits(['testAudio', 'testVideo'])

const popupStore = useStartChatPopupsStore()
const {isChatApprovalActive, chatApprovalActionType} = storeToRefs(popupStore)
const {toggleChatApprovalPopup} = popupStore

const chat = chatStore()
const {savedAdvisor, initiatingChat} = storeToRefs(chat)

const userStore = usersStore()
const {currentUser, isUser, isAdmin} = storeToRefs(userStore)

const visible = computed(() => (savedAdvisor.value && isChatApprovalActive.value && (isUser.value || isAdmin.value)) || initiatingChat.value == true)
const chatPrice = computed(() => savedAdvisor.value ? parseFloat(savedAdvisor.value["per_minute_fee_" + actionType.value]) : 0)
const discountedPrice = computed(() => savedAdvisor.value ? AdvisorService.getDiscountedPrice(chatPrice.value, savedAdvisor.value) : 0);

const formatPrice = (price: number): string => price.toFixed(2);

const actionType = ref('');
watch(() => chatApprovalActionType.value, () => {
  if (chatApprovalActionType.value) {
    actionType.value = chatApprovalActionType.value
  }
})

const canChatDuration = computed(() => {
  return Math.floor(Number(currentUser.value.amount) /
    Math.max(userStore.calculateDiscountedFee(chatPrice.value, savedAdvisor.value), 0.01)) + savedAdvisor.value?.free_minutes_left
})

const chatTypeContent = {
  text: {
    submitText: "Text Chat",
    bg: '!bg-primary-100',
    text: 'text-primary-100',
    offer: 'text-primary-120 bg-primary-10',
  },
  audio: {
    submitText: "Voice Call",
    bg: '!bg-feature-100',
    text: 'text-feature-100',
    offer: 'text-feature-120 bg-feature-10',
    check: {
      text: 'Check if your microphone is plugged in and working.',
      buttonText: 'Test Microphone'
    }
  },
  video: {
    submitText: "Video Call",
    bg: '!bg-love-100',
    text: 'text-love-100',
    offer: 'text-love-120 bg-love-10',
    check: {
      text: 'Check if your microphone and camera are plugged in and working.',
      buttonText: 'Test Camera and Microphone'
    }
  }
}
</script>

<script lang="ts">
export default {
  name: 'SeStartChatPopup',
};
</script>

<style lang="scss">
</style>