import {computed, ref} from 'vue';
import {defineStore} from 'pinia';
import {getGlobalProperties} from "@/src/shared/helpers/getGlobalProperties";
import {getStorageValue, setCookie} from "@/src/helpers/permanentValueStorage";
import {theme} from "@/theme";

export const useBannerStore = defineStore('banner', () => {
  const {$route} = getGlobalProperties()

  const activePromo = ref(theme.promo.activePromo);
  const userClosedBanner = ref(activePromo.value ? getStorageValue(activePromo) == "true" : false);

  const pagesWithoutBanner = ['/dashboard/chat', '/dashboard/inbox'];

  const validPages = computed(() => !pagesWithoutBanner.includes($route.path));
  const isBannerVisible = computed(() => activePromo.value && !userClosedBanner.value && validPages.value);


  const closeBanner = () => {
    userClosedBanner.value = true;
    setCookie(getStorageValue(activePromo), true);
  };

  return {
    activePromo,
    isBannerVisible,
    closeBanner
  };
});
