<template>
  <se-popup :classes="'sm:w-[448px]'" @close="emits('close')" title="You run out of funds!" header-divider>
    
    <div class="flex flex-col justify-center items-center mt-4">
      <template v-if="props.chatInfo.advisor">
        <se-avatar-chat-option
          :id="props.chatInfo.advisor.id"
          :size="68"
          :name="props.chatInfo.advisor.name"
          :src="props.chatInfo.advisor.avatar"
          :chat-action="props.chatInfo.mode ? props.chatInfo.mode : 'text'"
          class="mx-auto"
        />
        <p class="text-h-m text-center max-w-[90%] mt-2"><b>{{props.chatInfo.advisor.name}}</b></p>
      </template>
      
      <p class="text-h-m text-center max-w-[90%] mt-2">Carry on with the same advisor?</p>
    </div>
    
    <template #footer>
      <div class="flex flex-col gap-16 mt-16">
        <se-button size="medium" class="w-full" @click="emits('submit')">
          Chat again
        </se-button>
        <se-button size="medium" class="w-full !ml-0" @click="emits('close')" type="tertiary">
          Cancel
        </se-button>
      </div>
    </template>
  </se-popup>
</template>

<script setup lang="ts">
import SePopup from "@/src/shared/ui-kit/popup/Popup.vue";
import SeAvatarChatOption from "@/src/shared/ui-kit/avatar-chat-option/AvatarChatOption.vue";
import SeButton from "@/src/shared/ui-kit/button/Button.vue";

const props = defineProps<{
  chatInfo: any,
}>();

const emits = defineEmits(['close', 'submit']);

</script>

<script lang="ts">
export default {
  name: 'SeContinueWithSameAdvisorPopup',
};
</script>