<template>
  <div v-if="isBannerVisible"
       class="flex items-center justify-center w-full px-16 md:px-32 sm:h-[80px] bg-cover bg-center"
       :style="{backgroundImage: 'url(' + bannerBg + ')'}">
    <div class="py-12 !pl-0 w-full xs:w-auto">
      <div class="flex items-center justify-start md:justify-center flex-col gap-[12px] sm:gap-3 sm:flex-row w-full xs:w-auto">
        <div
          class="header-banner text-b-s w-full xs:w-auto font-bold italic flex justify-start md:justify-center"
          :class="activePromoData.ribbonStyle"
          :style="{
            backgroundColor: isBannerStyleRibbon ? activePromoData.ribbonBgColor : '',
            color: activePromoData.ribbonTextColor
          }"
        >
          <p
              class="whitespace-nowrap"
              :style="{ backgroundColor: activePromoData.ribbonBgColor }"
              v-html="activePromoData.bannerTitle"
          />
          <p
              class="w-[105px] whitespace-nowrap"
              :style="{ backgroundColor: activePromoData.ribbonBgColor }"
          >{{ days }} : {{ hours }} : {{ minutes }} : {{ seconds }}</p>
        </div>
        <div class="flex items-center w-full justify-between xs:justify-center">
          <p class="banner-text text-white text-b-s md:text-h-l mr-16" v-html="activePromoData.bannerText"></p>
          <Link href="/advisors/top">
            <se-button type="tertiary" class="!px-32 hidden md:inline-block md:visible" :style="{color: activePromoData.ribbonTextColor}">
              {{ activePromoData.cta }}
            </se-button>
            <se-button type="tertiary" size="small" class="!px-24 inline-block md:hidden" :style="{color: activePromoData.ribbonTextColor}">
              {{ activePromoData.cta }}
            </se-button>
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {storeToRefs} from "pinia";
import {useBannerStore} from "@/src/store/banners";
import {theme} from '@/theme';
import Link from '@/src/components/Link';
import SeButton from '@/src/shared/ui-kit/button/Button';
import {usePromoBanner} from "@/src/shared/helpers/promoBanner";
import {getWindow} from '@/src/shared/helpers/getWindow';

const config = theme.promo.blackFriday;

const banner = useBannerStore()
const {isBannerVisible} = storeToRefs(banner);
const {closeBanner} = banner;

const {activePromoData} = usePromoBanner()

const {windowWidth} = getWindow()
const isMobile = computed(() => windowWidth.value < 768);
const bannerBg = computed(() =>
    isMobile.value && activePromoData.value.bannerBgMobileImage ? activePromoData.value.bannerBgMobileImage : activePromoData.value.bannerBgImage
);

const end = activePromoData.value.endDate;
const days = ref('')
const hours = ref('')
const minutes = ref('')
const seconds = ref('')
const isBannerStyleRibbon = ref(activePromoData.value.ribbonStyle === 'ribbon')

const timer = ref();

function showRemaining() {
  const now = new Date();
  const distance = end - now;
  if (distance < 0) {
    clearInterval(timer.value);
    return;
  }
  
  const _second = 1000;
  const _minute = _second * 60;
  const _hour = _minute * 60;
  const _day = _hour * 24;
  days.value = ('0' + Math.floor(distance / _day)).slice(-2);
  hours.value = ('0' + Math.floor((distance % _day) / _hour)).slice(-2);
  minutes.value = ('0' + Math.floor((distance % _hour) / _minute)).slice(-2);
  seconds.value = ('0' + Math.floor((distance % _minute) / _second)).slice(-2);
}

onMounted(() => {
  showRemaining()
  timer.value = setInterval(showRemaining, 1000);
})

onUnmounted(() => {
  clearInterval(timer.value);
})
</script>

<script lang="ts">
export default {
  name: 'SeHeaderBlackFridayBanner',
};
</script>

<style lang="scss">
.header-banner {
  &.ribbon {
    @apply w-full xs:w-fit px-16 py-4 gap-20;
    --r: .6em;
    line-height: 16px;
    clip-path: polygon(0 0, 100% 0, calc(100% - var(--r)) 50%, 100% 100%, 0 100%, var(--r) 50%);
  }

  &.rounded-split {
    @apply gap-[2px];
    p {
      @apply px-12 py-[4px] sm:py-8 leading-[16px] rounded-8;
      &:first-child {
        @apply rounded-r-none;
      }
      &:last-child {
        @apply w-[121px] rounded-l-none;
      }
    }
  }

  &.rounded-separate {
    @apply gap-[8px];
    p {
      @apply px-12 py-[4px] sm:py-8 leading-[16px] rounded-[4px];
      &:last-of-type {
        @apply w-[130px];
      }
    }
  }
}


.banner-text br {
  @apply xs:hidden
}
</style>
