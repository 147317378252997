<template>
  <div class="relative inline-block">
    <se-avatar
      :id="props.id"
      :name="props.name"
      :src="props.src"
      :size="props.size"
    />
    <se-chat-option class="absolute bottom-0 right-0" :size="20" :chat-action="props.chatAction" :is-declined="isDeclined" />
  </div>
</template>

<script lang="ts" setup>
import SeAvatar from '../avatar/Avatar';
import SeChatOption from '../chat-option/ChatOption';

const props = withDefaults(defineProps<{
  id?: number,
  size?: number,
  name?: string,
  src?: string,
  chatAction?: string,
  isDeclined?: boolean
}>(), {
  id: 0,
  size: 48,
  name: '',
  src: '',
  chatAction: 'text',
  isDeclined: false
});

</script>

<script lang="ts">
export default {
  name: 'SeAvatarChatOption',
};
</script>
