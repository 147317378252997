import {AboutConfig} from "../../_core/types/theme/About.type";

const about: AboutConfig =  {
    title: 'About PsychicVOP',
    content: [
        'At PsychicVop, we believe that finding the right psychic makes all the difference. That’s why we connect you with a trusted, vetted advisor who truly understands your needs—helping you gain insight, clarity, and direction when you need it most.',
        'Our platform is designed to provide you with access to a diverse range of highly skilled and experienced psychics who are committed to offering you the guidance and insights you need - day or night.',
        'With our careful selection process, you can trust that every advisor on our platform has been thoroughly screened to ensure their expertise and professionalism.',
        'Life’s biggest questions don’t have to be faced alone. Whether you’re seeking guidance on love, career, or personal growth, PsychicVop is here to illuminate the path ahead. Let us help you navigate uncertainty and bring light to the unknown—because tomorrow’s answers start today.'
    ],
    seo: {
        title: 'About us - PsychicVOP',
        description: 'Experience personalized readings on PsychicVOP. Select your advisor, start a chat, and enjoy insightful guidance. Discover the power of meaningful connections with our trusted advisors.',
    },
    topAdvisors: {
        title: 'Check Our Top Advisors',
        ctaText: 'Show All Advisors'
    }
};

// @ts-ignore
export default about;
