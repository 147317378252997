import {computed, ref} from 'vue';
import {defineStore} from 'pinia';
import {usersStore} from "@/src/store/users";
import {chatStore} from "@/src/store/chat";

export const useStartChatPopupsStore = defineStore('startChatPopups', () => {
  //missed chat
  const isActiveMissedChatPopup = ref<boolean>(false);

  function setMissedChatPopupState(payload: boolean) {
    isActiveMissedChatPopup.value = payload;
  }

  //chat confirmation
  const isActiveTextChatApproval = ref<boolean>(false);
  const isActiveAudioChatApproval = ref<boolean>(false);
  const isActiveVideoChatApproval = ref<boolean>(false);

  const isChatApprovalActive = computed(() => {
    return isActiveTextChatApproval.value || isActiveAudioChatApproval.value || isActiveVideoChatApproval.value
  })

  const chatApprovalActionType = computed(() => {
    if (isActiveTextChatApproval.value) return 'text'
    else if (isActiveAudioChatApproval.value) return 'audio'
    else if (isActiveVideoChatApproval.value) return 'video'
    return ''
  })

  function toggleChatApprovalPopup(show: boolean, mode: string) {
    const users = usersStore()
    const chat = chatStore()
    if (
      show
      && !chat.currentUserHasEnoughAmountForChat(users.currentUser, chat.savedAdvisor, mode)
      && !users.getFreeMinutes(chat.savedAdvisor)
    ) {
      // Showing not enough money dialog instead of chat confirmation if there is not enough
      // money to start the chat.
      chat.setNotEnoughMoney(true)
    } else {
      switch (mode) {
        case 'text':
          isActiveTextChatApproval.value = show
          break
        case 'audio':
          isActiveAudioChatApproval.value = show
          break
        case 'video':
          isActiveVideoChatApproval.value = show
          break
        default:
          console.error(`${mode} is not supported.`)
      }
    }
  }

  return {
    //missed chat
    isActiveMissedChatPopup,
    setMissedChatPopupState,

    //start chat
    isChatApprovalActive,
    chatApprovalActionType,
    toggleChatApprovalPopup
  };
});
